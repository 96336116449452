import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import moment from "moment";
import { LAZYMOREDETAILS } from "../../API/queries";
import { useLazyQuery } from "@apollo/client";
import { ModalTable } from "../../old-pages/ModalTable";

export const MoreDetailsModal = (props: any) => {
  const { id, children } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [getMoreDetails, { loading, data }] = useLazyQuery(LAZYMOREDETAILS);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAnchorEl, data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    getMoreDetails({ variables: { id: id } });
  };

  const snakeToTitle = (s: String) =>
    s
      .replace(/^[-_]*(.)/, (_, c: String) => c.toUpperCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c: String) => " " + c.toUpperCase()); // First char after each -/_

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const columnLabels1: { [key: string]: any } = {
    development_id: "Development ID",
    id: "ID",
    district_name: "District",
    development_name: "Development Name",
    developer: "Developer",
    peza_accreditation: "PEZA Accreditation",
    lease_type: "Lease Type",
    transaction_quarter: "Transaction Quarter",
    transaction_year: "Transaction Year",
    assigned_broker: "Assigned Broker",
    broker_name: "Co-broker",
    contact_person: "Signatory",
    contact_email: "Signatory's E-mail",
  };
  const columnLabels2: { [key: string]: any } = {
    floor_number: "Floor/Unit Number",
    gross_leasable_area_sqm: "Gross Leasable Area (in SQM)",
    handover_date: "Handover Date",
    fitout_period: "Fit-out Period",
    lease_commencement_date: "Commencement Date",
    renewal_notice: "Renewal Notice",
    lease_expiration_date: "Expiration Date",
    lease_term: "Lease Term",
    pretermination_date: "Pre-termination Date",
    pre_termination: "Pre-termination",
  };
  const columnLabels3: { [key: string]: any } = {
    current_rent_sqm: "Current Rent per SQM",
    escalation_rate: "Escalation Rate",
    year_applied: "Year Applied",
    common_area_maintenance_dues: "Building Dues",
    advance_rent: "Advance Rent",
    security_deposit: "Security Deposit",
    reservation_deposit: "Reservation Deposit",
    vetting_fee: "Vetting Fee",
    parking_slots: "Parking Slots",
    parking_rate: "Parking Rate",
  };
  return (
    <>
      <Button
        aria-describedby={id}
        variant="text"
        color="primary"
        onClick={handleClick}
      >
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card>
          <CardContent
            sx={{
              minWidth: "650px",
            }}
          >
            {loading || !data ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  {data?.details[0].client_name}
                </Typography>
                <Grid container justifyContent="space-between">
                  <Grid item xs={3}>
                    {Object.keys(columnLabels1).map(
                      (row: String, index: number) => {
                        return index <= 1 ? null : (
                          <>
                            <Typography
                              variant="body2"
                              color="text.primary"
                              sx={{
                                fontWeight: 500,
                              }}
                            >
                              {columnLabels1[`${row}`]}:{" "}
                              <Typography
                                display="inline"
                                variant="body2"
                                color="text.secondary"
                              >
                                {typeof data?.details[0][`${row}`] ===
                                  "number" &&
                                  data?.details[0][`${row}`].toLocaleString()}
                                {row.includes("date") &&
                                  data?.details[0][`${row}`] &&
                                  moment(data?.details[0][`${row}`]).format(
                                    "MMM DD, YYYY"
                                  )}
                                {!row.includes("date") &&
                                  typeof data?.details[0][`${row}`] ===
                                    "string" &&
                                  data?.details[0][`${row}`]}
                                {/* {data?.details[0][`${row}`]} */}
                              </Typography>
                            </Typography>
                          </>
                        );
                      }
                    )}
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={4}>
                    {Object.keys(columnLabels2).map(
                      (row: String, index: number) => (
                        <>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                              fontWeight: 500,
                            }}
                          >
                            {columnLabels2[`${row}`]}:{" "}
                            <Typography
                              display="inline"
                              variant="body2"
                              color="text.secondary"
                            >
                              {typeof data?.details[0][`${row}`] === "number" &&
                                data?.details[0][`${row}`].toLocaleString()}
                              {row.includes("date") &&
                                data?.details[0][`${row}`] &&
                                moment(data?.details[0][`${row}`]).format(
                                  "MMM DD, YYYY"
                                )}
                              {!row.includes("date") &&
                                typeof data?.details[0][`${row}`] ===
                                  "string" &&
                                data?.details[0][`${row}`]}
                              {/* {data?.details[0][`${row}`]} */}
                            </Typography>
                          </Typography>
                        </>
                      )
                    )}
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={4}>
                    {Object.keys(columnLabels3).map(
                      (row: String, index: number) => (
                        <>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{
                              fontWeight: 500,
                            }}
                          >
                            {columnLabels3[`${row}`]}:{" "}
                            <Typography
                              display="inline"
                              variant="body2"
                              color="text.secondary"
                            >
                              {typeof data?.details[0][`${row}`] === "number" &&
                                data?.details[0][`${row}`].toLocaleString()}
                              {row.includes("date") &&
                                data?.details[0][`${row}`] &&
                                moment(data?.details[0][`${row}`]).format(
                                  "MMM DD, YYYY"
                                )}
                              {!row.includes("date") &&
                                typeof data?.details[0][`${row}`] ===
                                  "string" &&
                                data?.details[0][`${row}`]}

                              {/* {data?.details[0][`${row}`]} */}
                            </Typography>
                          </Typography>
                        </>
                      )
                    )}
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      Escalation Rate Table
                      <Typography
                        display="inline"
                        variant="body2"
                        color="text.secondary"
                      >
                        <ModalTable
                          commencement={moment(
                            data?.details[0].lease_commencement_date
                          ).format("MM/DD/YYYY")}
                          expiration={moment(
                            data?.details[0].lease_expiration_date
                          ).format("MM/DD/YYYY")}
                          escalation={data?.details[0].escalation_rate}
                          gla={data?.details[0].gross_leasable_area_sqm}
                          year_applied={data?.details[0].year_applied}
                          rent_per_sqm={data?.details[0].rent_per_sqm}
                        >
                          More Details
                        </ModalTable>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};
