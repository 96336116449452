import gql from "graphql-tag";

export const ADDNEWCONTRACT = gql`
  mutation AddNewContract(
    $development_id: uuid
    $client_name: String
    $escalation_rate: numeric
    $year_applied: Int
    $gross_leasable_area_sqm: numeric
    $industry: String
    $lease_commencement_date: date
    $lease_expiration_date: date
    $net_usable_floor: numeric
    $rent_per_sqm: numeric
    $transaction_counterparty: String
    $transaction_quarter: Int
    $transaction_year: Int
    $renewal_notice: String
    $tenant: String
    $contact_person: String
    $lease_type: String
    $fitout_period: Int
    $succeeding_escalation_rates: String
    $parking_allocation: numeric
    $parking_rate: numeric
    $pre_termination: Int
    $reservation_deposit: numeric
    $security_deposit: numeric
    $vetting_fee: numeric
    $advance_rent: numeric
    $common_area_maintenance_dues: numeric
    $broker_name: String
    $contact_email: String
    $contact_number: String
    $floor_number: String
    $contract_url: String
  ) {
    insert_commercial_leasing_lease_contracts(
      objects: {
        escalation_rate: $escalation_rate
        year_applied: $year_applied
        development_id: $development_id
        gross_leasable_area_sqm: $gross_leasable_area_sqm
        industry: $industry
        client_name: $client_name
        lease_commencement_date: $lease_commencement_date
        lease_expiration_date: $lease_expiration_date
        net_usable_floor: $net_usable_floor
        rent_per_sqm: $rent_per_sqm
        fitout_period: $fitout_period
        transaction_counterparty: $transaction_counterparty
        transaction_quarter: $transaction_quarter
        transaction_year: $transaction_year
        renewal_notice: $renewal_notice
        tenant: $tenant
        floor_number: $floor_number
        lease_type: $lease_type
        contact_person: $contact_person
        contact_email: $contact_email
        contact_number: $contact_number
        succeeding_escalation_rates: $succeeding_escalation_rates
        advance_rent: $advance_rent
        security_deposit: $security_deposit
        reservation_deposit: $reservation_deposit
        parking_rate: $parking_rate
        vetting_fee: $vetting_fee
        common_area_maintenance_dues: $common_area_maintenance_dues
        pre_termination: $pre_termination
        broker_name: $broker_name
        parking_allocation: $parking_allocation
        contract_url: $contract_url
      }
    ) {
      returning {
        transaction_id
      }
    }
  }
`;

export const DELETECONTRACT = gql`
  mutation deleteContract($transaction_id: [uuid!]) {
    delete_commercial_leasing_lease_contracts(
      where: { transaction_id: { _in: $transaction_id } }
    ) {
      affected_rows
    }
  }
`;

export const UPDATECONTRACT = gql`
  mutation UpdateContract(
    $transaction_id: uuid!
    $development_id: uuid
    $client_name: String
    $escalation_rate: numeric
    $year_applied: Int
    $gross_leasable_area_sqm: numeric
    $industry: String
    $lease_commencement_date: date
    $lease_expiration_date: date
    $net_usable_floor: numeric
    $rent_per_sqm: numeric
    $floor_number: String
    $transaction_counterparty: String
    $transaction_quarter: Int
    $transaction_year: Int
    $renewal_notice: String
    $tenant: String
    $fitout_period: Int
    $contact_person: String
    $lease_type: String
    $succeeding_escalation_rates: String
    $parking_allocation: numeric
    $parking_rate: numeric
    $pre_termination: Int
    $reservation_deposit: numeric
    $security_deposit: numeric
    $vetting_fee: numeric
    $advance_rent: numeric
    $common_area_maintenance_dues: numeric
    $broker_name: String
    $contact_email: String
    $contact_number: String
    $contract_url: String
  ) {
    update_commercial_leasing_lease_contracts_by_pk(
      _set: {
        client_name: $client_name
        development_id: $development_id
        escalation_rate: $escalation_rate
        year_applied: $year_applied
        gross_leasable_area_sqm: $gross_leasable_area_sqm
        industry: $industry
        floor_number: $floor_number
        fitout_period: $fitout_period
        lease_commencement_date: $lease_commencement_date
        lease_expiration_date: $lease_expiration_date
        net_usable_floor: $net_usable_floor
        rent_per_sqm: $rent_per_sqm
        transaction_counterparty: $transaction_counterparty
        transaction_quarter: $transaction_quarter
        transaction_year: $transaction_year
        renewal_notice: $renewal_notice
        tenant: $tenant
        lease_type: $lease_type
        contact_person: $contact_person
        contact_email: $contact_email
        contact_number: $contact_number
        succeeding_escalation_rates: $succeeding_escalation_rates
        advance_rent: $advance_rent
        security_deposit: $security_deposit
        reservation_deposit: $reservation_deposit
        parking_rate: $parking_rate
        vetting_fee: $vetting_fee
        common_area_maintenance_dues: $common_area_maintenance_dues
        pre_termination: $pre_termination
        broker_name: $broker_name
        parking_allocation: $parking_allocation
        contract_url: $contract_url
      }
      pk_columns: { transaction_id: $transaction_id }
    ) {
      transaction_id
    }
  }
`;

export const ADDNEWDEVELOPMENT = gql`
  mutation addNewDevelopment($development_name: String, $location: String) {
    insert_developments_schema_developments_copy(
      objects: { development_name: $development_name, location: $location }
    ) {
      returning {
        development_id
      }
    }
  }
`;
