import { ActionType } from "../action-types";
import { Action } from "../actions";

export interface superState {
  token: string;
  isAuthenticated: boolean;
}

export const initialState = {
  token: "",
  isAuthenticated: false,
};

export const tokenstore = (
  state: superState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.ADDTOKEN:
      return { ...action.payload };
    default:
      return state;
  }
};
