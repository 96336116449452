import { Doughnut } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Chart as ChartJS, CategoryScale, registerables } from "chart.js";

ChartJS.register(...registerables, CategoryScale);
export const TrafficByDevice = (props: any) => {
  const { percentShareObject } = props;
  const percentShareData = percentShareObject.map(
    (row: any) => row.percent_share
  );
  const percentShareLabels = percentShareObject.map(
    (row: any, index: number) => row.industry
  );
  const othersData = percentShareData
    .filter((row: any, index: number) => index >= 5)
    .reduce((a: any, b: any) => a + b, 0);
  const finalData = percentShareData.filter(
    (row: any, index: any) => index < 5
  );
  // .push(othersData);
  const finalLabels = percentShareLabels.filter(
    (row: any, index: any) => index < 5
  );
  finalData.push(othersData);
  finalLabels.push("Others");

  // const chartRef = useRef<any>();
  // const onClick = (event: any) => {
  //   console.log(getDatasetAtEvent(chartRef.current, event));
  // }

  const pieChartColors = [
    "#3F51B5",
    "#e53935",
    "#FB8C00",
    "#53DB27",
    "#DB27C2",
    "#000000",
    "#1EE6CE",
    "#9C1DE6",
    "#DC0BE6",
    "#E6BC00",
  ];
  const data = {
    datasets: [
      {
        data: finalData,
        backgroundColor: pieChartColors,
        borderWidth: 1,
        borderColor: "#FFFFFF",
        hoverBorderColor: "#FFFFFF",
      },
    ],
    labels: finalLabels,
  };

  const options: any = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        displayColors: false,
      },
    },
  };

  const devices = finalData.map((row: any, index: number) => ({
    title: finalLabels[index],
    value: finalData[index],
    color: pieChartColors[index],
  }));

  return (
    <Card {...props}>
      <CardHeader title="Overall Tenancy Profile" />

      <Divider
        sx={{
          borderColor: "neutral.200",
        }}
      />
      <CardContent>
        <Box>
          <Doughnut
            // ref={chartRef}
            data={data}
            options={options}
            // onClick={onClick}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <Grid container justifyContent="center" spacing={3}>
            {devices.length > 0
              ? devices.map(({ color, title, value }: any) => (
                  <Grid item>
                    <Box
                      key={title}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography color="textPrimary" variant="body2">
                        {title}
                      </Typography>
                      <Typography style={{ color }} variant="h4">
                        {value}%
                      </Typography>
                    </Box>
                  </Grid>
                ))
              : null}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
