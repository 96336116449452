import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColumns,
  GridRowsProp,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { ALLLEASECONTRACTSVIEW, LEASINGREPORTVIEW } from "../../API/queries";
import { useQuery } from "@apollo/client";
import { Column, Data } from "../../utils/types";
import moment from "moment";
import { ModalTable } from "../../old-pages/ModalTable";
import { Button, Card } from "@mui/material";
import { Storage } from "aws-amplify";
import { QuickSearchToolbar } from "../../utils/quick-search-toolbar";

export default function Contracts() {
  type Table = {
    columns: Column[];
    rows: Data[];
  };
  const { error: errorView, data: dataView } = useQuery(LEASINGREPORTVIEW, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const [searchText, setSearchText] = useState("");

  function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const [leaseData, setData] = useState<Table>({
    columns: [
      { field: "id", headerName: "ID", type: "number", width: 80 },

      {
        field: "development_id",
        headerName: "Development ID",
        type: "string",
        width: 120,
        hide: true,
      },
      {
        field: "development_name",
        headerName: "Development Name*",
        type: "string",
        width: 250,
      },
      {
        field: "district_name",
        headerName: "District",
        type: "string",
        width: 220,
      },
      {
        field: "industry",
        headerName: "Industry",
        type: "string",
        width: 250,
      },
      {
        field: "client_name",
        headerName: "Client Name",
        type: "string",
        width: 250,
      },
      {
        field: "contact_person",
        headerName: "Contact Person",
        type: "string",
        width: 250,
      },
      {
        field: "contact_email",
        headerName: "Contact E-mail",
        type: "string",
        width: 250,
      },
      {
        field: "contact_number",
        headerName: "Contact Number",
        type: "string",
        width: 250,
      },
      {
        field: "floor_number",
        headerName: "Floor Number",
        type: "string",
        width: 250,
      },
      {
        field: "transaction_year",
        headerName: "Transaction Year",
        type: "number",
        valueFormatter: (params: GridValueFormatterParams) => params.value,
        width: 250,
      },
      {
        field: "transaction_quarter",
        headerName: "Transaction Quarter",
        type: "number",
        width: 250,
      },
      {
        field: "lease_commencement_date",
        headerName: "Commencement Date",
        type: "date",
        width: 250,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value) {
            return moment(params.value).format("MMM DD, YYYY");
          } else {
            return null;
          }
        },
      },
      {
        field: "lease_expiration_date",
        headerName: "Expiration Date",
        type: "date",
        width: 195,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value) {
            return moment(params.value).format("MMM DD, YYYY");
          } else {
            return null;
          }
        },
      },
      {
        field: "developer",
        headerName: "Developer",
        type: "string",
        width: 220,
      },
      {
        field: "ultimate_developer",
        headerName: "Ultimate Developer",
        type: "string",
        width: 220,
      },
      {
        field: "lease_term",
        headerName: "Lease Term",
        type: "number",
        width: 220,
      },
      {
        field: "current_rent_sqm",
        headerName: "Current Rent per SQM",
        type: "number",
        width: 185,
      },
      {
        field: "rent_per_sqm",
        headerName: "Rent per SQM",
        type: "number",
        width: 185,
      },
      {
        field: "renewal_notice",
        headerName: "Renewal Notice (in days)",
        type: "number",
        width: 250,
      },
      {
        field: "fitout_period",
        headerName: "Fit-out Period (in days)",
        type: "number",
        width: 250,
      },
      {
        field: "handover_date",
        headerName: "Hand-over Date",
        type: "date",
        width: 250,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value) {
            return moment(params.value).format("MMM DD, YYYY");
          } else {
            return null;
          }
        },
      },
      {
        field: "escalation_rate",
        headerName: "Escalation Rate (in %)",
        type: "number",
        width: 260,
      },
      {
        field: "escalation_rates_with_dates",
        headerName: "Escalation Rates with Dates",
        type: "string",
        width: 260,
        renderCell: (params: any | null) => {
          return (
            <ModalTable
              commencement={moment(params.row.lease_commencement_date).format(
                "MM/DD/YYYY"
              )}
              expiration={moment(params.row.lease_expiration_date).format(
                "MM/DD/YYYY"
              )}
              escalation={params.row.escalation_rate}
              gla={params.row.gross_leasable_area_sqm}
              year_applied={params.row.year_applied}
              rent_per_sqm={params.row.rent_per_sqm}
            >
              More Details
            </ModalTable>
          );
        },
      },

      {
        field: "gross_leasable_area_sqm",
        headerName: "Gross Leasable Area (in SQM)",
        type: "number",
        width: 230,
      },
      {
        field: "net_usable_floor",
        headerName: "Net Usable Floor Area (in SQM)",
        type: "number",
        width: 250,
      },
      {
        field: "year_applied",
        headerName: "Year Applied",
        type: "number",
        width: 250,
      },
      {
        field: "assigned_broker",
        headerName: "Assigned Broker",
        type: "singleSelect",
        width: 250,
      },
      {
        field: "broker_name",
        headerName: "Co-Broker",
        type: "string",
        width: 220,
      },
      {
        field: "contact_person",
        headerName: "Contact Person",
        type: "string",
        width: 250,
      },
      {
        field: "lease_type",
        headerName: "Lease Type",
        type: "singleSelect",
        width: 250,
        valueOptions: [
          "1st Site",
          "Consolidation",
          "Pre-termination",
          "Relocation",
          "Relocation & Expansion",
          "Renewal",
          "Renewal/Relocation",
          "Seat Leasing",
          "Htl Mgt",
          "Htl Mgt/JV",
          "JV",
          "Land Lease",
          "Sale",
          "Sale & Lease",
        ],
      },
      {
        field: "peza_accreditation",
        headerName: "PEZA Accreditation*",
        type: "string",
        width: 250,
      },
      {
        field: "advance_rent",
        headerName: "Advance Rent (Months)",
        type: "number",
        width: 220,
      },
      {
        field: "security_deposit",
        headerName: "Security Deposit (Months)",
        type: "number",
        width: 220,
      },
      {
        field: "reservation_deposit",
        headerName: "Reservation Deposit (Months)",
        type: "number",
        width: 250,
      },
      {
        field: "parking_allocation",
        headerName: "Parking Allocation",
        type: "number",
        width: 220,
      },
      {
        field: "parking_rate",
        headerName: "Parking Rate",
        type: "number",
        width: 250,
      },
      {
        field: "vetting_fee",
        headerName: "Vetting Fee Per SQM",
        type: "number",
        width: 220,
      },
      {
        field: "common_area_maintenance_dues",
        headerName: "CUSA (PHP/SQM)",
        type: "number",
        width: 250,
      },
      {
        field: "pre_termination",
        headerName: "Pre-Termination",
        type: "number",
        width: 250,

        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value === 0 || params.value === null) {
            return "None";
          }
        },
      },
      {
        field: "pretermination_date",
        headerName: "Pre-termination Date",
        type: "date",
        width: 250,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value) {
            return moment(params.value).format("MMM DD, YYYY");
          } else {
            return null;
          }
        },
      },
      {
        field: "contract_url",
        headerName: "Contract",
        type: "number",
        width: 250,
        renderCell: (params: any | null) => {
          return (
            <Button
              onClick={() => downloadTrigger(params.value)}
              disabled={params.value === null}
            >
              Download
            </Button>
          );
        },
      },
    ],
    rows: [],
  });

  const downloadContract = async (blob: any, filename: string) => {
    const url = URL.createObjectURL(blob.Body);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };
  const downloadTrigger = async (filename: string) => {
    Storage.configure({ level: "private" });
    try {
      const result = await Storage.get(filename, { download: true });
      downloadContract(result, filename);
    } catch (downloadErr: any) {
      throw new Error(downloadErr);
    }
  };

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = leaseData.rows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    if (searchValue) {
      setData({
        columns: leaseData.columns,
        rows: filteredRows,
      });
    } else {
      setData({
        ...leaseData,
        rows: dataView.commercial_leasing_leasing_report_view.map(
          (o: any, idx: number) => ({ id: idx + 1, ...o })
        ),
      });
    }
  };

  useEffect(() => {
    if (dataView && searchText === "") {
      const tableData = dataView.commercial_leasing_leasing_report_view.map(
        (o: any, idx: number) => ({ id: idx + 1, ...o })
      );

      setData({
        columns: leaseData.columns,
        rows: tableData,
      });
    }
    if (errorView) {
      alert(errorView);
    }
  }, [dataView, errorView]);

  const rows: GridRowsProp = leaseData.rows;
  const columns: GridColumns = leaseData.columns;

  return (
    <div style={{ height: 500, width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event: any) => requestSearch(event.target.value),
                  clearSearch: () => {
                    requestSearch("");
                    setData({
                      ...leaseData,
                      rows: dataView.commercial_leasing_leasing_report_view.map(
                        (o: any, idx: number) => ({ id: idx + 1, ...o })
                      ),
                    });
                  },
                },
              }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
