import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import { useFormData } from "../../context";
import { Data } from "../../utils/types";
import {
  Grid,
  Button,
  CardActions,
  CardContent,
  Paper,
  FormControl,
  Box,
  Typography,
} from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";

export default function FourthPage(props: any) {
  const { formStep, prevFormStep, nextFormStep } = props;
  const { contractFile, setContractFile, setFormValues } = useFormData();
  //   const [contractFile, setContractFile] = useState<File>();

  const uploadContract = async (e: File[]) => {
    const fileList = e[0];
    if (!fileList) return;

    await setContractFile(fileList);
  };

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Data>();

  const onSubmit = async (values: Data) => {
    if (contractFile) {
      setFormValues(values);
      nextFormStep();
    }
    nextFormStep();
  };

  useEffect(() => {
    if (contractFile) {
      setValue("contract_url", contractFile!.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractFile]);
  return (
    <Paper
      sx={{
        display: formStep === 3 ? "flex" : "none",
        justifyContent: "center",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  fontWeight: 500,
                }}
              >
                Certificate of Registration (BIR Form 2303) of Entity to be
                Billed
              </Typography>
              <Button>Upload</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  fontWeight: 500,
                }}
              >
                PEZA Certificate (if applicable/zero rated sales)
              </Typography>
              <Button>Upload</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  fontWeight: 500,
                }}
              >
                Certificate of Incorporation (SEC) of Entity to be Billed
              </Typography>
              <Button>Upload</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  fontWeight: 500,
                }}
              >
                Signed Lease Contract*
              </Typography>
              <Button>Upload</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  fontWeight: 500,
                }}
              >
                Fee Agreement
              </Typography>
              <Button>Upload</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  fontWeight: 500,
                }}
              >
                Sharing of Agents* (upload excel file with names of agent and
                percentages)
              </Typography>
              <Button>Upload</Button>
            </Grid>
          </Grid>
          {/* <LinearProgressWithLabel value={progress} /> */}
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={prevFormStep}>back</Button>
          <Button type="submit">Next</Button>
        </CardActions>
      </form>
    </Paper>
  );
}
