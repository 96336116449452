// import styles from "../../styles/styles.module.scss";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { useFormData } from "../../context";
import { Data } from "../../utils/types";
import {
  Grid,
  Button,
  CardActions,
  CardContent,
  Paper,
  Divider,
  Typography,
  Backdrop,
  Card,
  Fade,
  Modal,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";

export default function SummaryPage(props: any) {
  const { formStep, prevFormStep, nextFormStep } = props;
  const { data, contractFile, setFormValues } = useFormData();
  const [openModal, setOpenModal] = useState(false);
  // const [addContract, { loading: mutationLoading, error: mutationError }] =
  // useMutation(ADDNEWCONTRACT, {
  //   refetchQueries: [
  //     { query: ALLLEASECONTRACTSVIEW }, // DocumentNode object parsed with gql
  //     "LeaseContractsView", // Query name
  //   ],
  // });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    control,
  } = useForm<Data>();

  const onSubmit: SubmitHandler<Data> = async (datum: Data, e: any) => {
    // const data: { [key: string]: any } = datum;
    // Storage.configure({ level: "private" });
    // // if (data.development_id === "") {
    // //   // if development_id is empty, send null
    // //   data.development_id = null;
    // // }
    // if (contractFile) {
    //   try {
    //     await Storage.put(`${contractFile!.name}`, contractFile, {
    //       contentType: contractFile!.type,
    //       progressCallback(progress: { loaded: any; total: any }) {
    //         const percentComplete = (progress.loaded / progress.total) * 100;
    //         setProgress(percentComplete);
    //       },
    //     });
    //     await Storage.get(`${contractFile!.name}`);
    //     data.contract_url = contractFile!.name;
    //   } catch (uploadErr: any) {
    //     alert(uploadErr);
    //   }
    // }
    // const response = await addContract({ variables: datum });
    // props.refetch();
    // if (!mutationError) {
    //   reset();
    //   props.handleModal();
    //   setProgress(0);
    // } else {
    //   alert(mutationError);
    // }
    handleModal();
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    console.log(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tempData: { [key: string]: any } = { ...data };
  const columnLabels1: { [key: string]: any } = {
    development_name: "Development Name",
    client_name: "Tenant",
    developer: "Developer",
    lease_type: "Lease Type",
    transaction_quarter: "Transaction Quarter",
    transaction_year: "Transaction Year",
    transaction_counterparty: "Assigned Broker",
    assigned_broker_2: "Assigned Broker 2",
    assigned_broker_3: "Assigned Broker 3",
    assigned_broker_4: "Assigned Broker 4",
    contact_person: "Signatory",
    contact_email: "Signatory's E-mail",
  };
  const columnLabels2: { [key: string]: any } = {
    floor_number: "Floor/Unit Number",
    gross_leasable_area_sqm: "Gross Leasable Area (in SQM)",
    fitout_period: "Fit-out Period",
    lease_commencement_date: "Commencement Date",
    renewal_notice: "Renewal Notice",
    lease_expiration_date: "Expiration Date",
    lease_term: "Lease Term",
    pre_termination: "Pre-termination",
  };
  const columnLabels3: { [key: string]: any } = {
    rent_per_sqm: "Monthly Rent per SQM",
    escalation_rate: "Escalation Rate",
    year_applied: "Year Applied",
    common_area_maintenance_dues: "Building Dues",
    advance_rent: "Advance Rent",
    security_deposit: "Security Deposit",
    reservation_deposit: "Reservation Deposit",
    vetting_fee: "Vetting Fee",
    parking_slots: "Parking Slots",
    parking_rate: "Parking Rate",
    contract_url: "Contract File",
  };

  return (
    <Paper
      sx={{
        display: formStep === 4 ? "flex" : "none",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal
          aria-labelledby="transition-modal-delete-contract"
          aria-describedby="transition-modal-description-delete"
          open={openModal}
          onClose={handleModal}
          closeAfterTransition
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Fade in={openModal}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  Are all the details in the summary final? Details will not be
                  changed after submitted.
                </Typography>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button size="small" onClick={handleModal}>
                    No
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Yes
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
        <CardContent>
          <>
            <Typography gutterBottom variant="h5" component="div">
              Summary
            </Typography>
            <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                {Object.keys(columnLabels1).map(
                  (row: String, index: number) => (
                    <>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        {columnLabels1[`${row}`]}:{" "}
                        <Typography
                          display="inline"
                          variant="body2"
                          color="text.secondary"
                        >
                          {typeof tempData[`${row}`] === "number" &&
                            tempData[`${row}`].toLocaleString()}
                          {row.includes("date") &&
                            tempData[`${row}`] &&
                            moment(tempData[`${row}`]).format("MMM DD, YYYY")}
                          {!row.includes("date") &&
                            typeof tempData[`${row}`] === "string" &&
                            tempData[`${row}`]}
                          {/* {tempData[`${row}`]} */}
                        </Typography>
                      </Typography>
                    </>
                  )
                )}
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={4}>
                {Object.keys(columnLabels2).map(
                  (row: String, index: number) => (
                    <>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        {columnLabels2[`${row}`]}:{" "}
                        <Typography
                          display="inline"
                          variant="body2"
                          color="text.secondary"
                        >
                          {typeof tempData[`${row}`] === "number" &&
                            tempData[`${row}`].toLocaleString()}
                          {row.includes("date") &&
                            tempData[`${row}`] &&
                            moment(tempData[`${row}`]).format("MMM DD, YYYY")}
                          {!row.includes("date") &&
                            typeof tempData[`${row}`] === "string" &&
                            tempData[`${row}`]}
                          {/* {tempData[`${row}`]} */}
                        </Typography>
                      </Typography>
                    </>
                  )
                )}
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={4}>
                {Object.keys(columnLabels3).map(
                  (row: String, index: number) => (
                    <>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        {columnLabels3[`${row}`]}:{" "}
                        <Typography
                          display="inline"
                          variant="body2"
                          color="text.secondary"
                        >
                          {typeof tempData[`${row}`] === "number" &&
                            tempData[`${row}`].toLocaleString()}
                          {row.includes("date") &&
                            tempData[`${row}`] &&
                            moment(tempData[`${row}`]).format("MMM DD, YYYY")}
                          {!row.includes("date") &&
                            typeof tempData[`${row}`] === "string" &&
                            tempData[`${row}`]}
                          {/* {tempData[`${row}`]} */}
                        </Typography>
                      </Typography>
                    </>
                  )
                )}
              </Grid>
            </Grid>
          </>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={prevFormStep}>back</Button>
          <Button onClick={handleModal}>Submit</Button>
        </CardActions>
      </form>
    </Paper>
  );
}
