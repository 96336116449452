import { ActionType } from "../action-types";
import { Action } from "../actions";
import { Data } from "../../utils/types";

export const dataState = {
  transaction_id: undefined,
  development_id: undefined,
  industry: undefined,
  client_name: undefined,
  transaction_year: undefined,
  transaction_quarter: undefined,
  lease_commencement_date: undefined,
  lease_expiration_date: undefined,
  rent_per_sqm: undefined,
  monthly_rent: undefined,
  escalation_rate: undefined,
  year_applied: undefined,
  gross_leasable_area_sqm: undefined,
  net_usable_floor: undefined,
  transaction_counterparty: undefined,
  renewal_terms: undefined,
  renewal_notice: undefined,
  tenant: undefined,
  contact_person: undefined,
  contact_email: undefined,
  contact_number: undefined,
  lease_type: undefined,
  succeeding_escalation_rates: undefined,
  advance_rent: undefined,
  security_deposit: undefined,
  reservation_deposit: undefined,
  parking_rate: undefined,
  vetting_fee: undefined,
  common_area_maintenance_dues: undefined,
  pre_termination: undefined,
  parking_allocation: undefined,
  broker_name: undefined,
  floor_number: undefined,
  fitout_period: undefined,
  contract_url: undefined,
};

export const checkedRowStore = (state: Data = dataState, action: Action) => {
  switch (action.type) {
    case ActionType.ADDCHECKEDROW:
      return { ...action.payload };
    default:
      return state;
  }
};
