import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import AddIcon from "@mui/icons-material/Add";
import { Auth } from "aws-amplify";
import { Box, Button, Container, List } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { initialState } from "../../state/reducers/tokenStore";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import BarChartIcon from "@mui/icons-material/BarChart";

// import { NotificationsMenu } from "./Notifications";
import { NavItem } from "./nav-item";
import AuthToolbar from "./auth-toolbar";
const drawerWidth = 240;

const items = [
  {
    href: "/dashboard",
    icon: <BarChartIcon fontSize="small" />,
    title: "Dashboard",
  },
  {
    href: "/contracts",
    icon: <AssignmentRoundedIcon fontSize="small" />,
    title: "Contracts",
  },
  {
    href: "/logs",
    icon: <AssessmentRoundedIcon fontSize="small" />,
    title: "Logs",
  },
];

export default function AuthDrawer(props: any) {
  let navigate = useNavigate();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const dispatch = useDispatch();
  const { addToken } = bindActionCreators(actionCreators, dispatch);
  const [name, setName] = useState<String>("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: 3,
          margin: 3,
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
          justifyContent: "space-around",
        }}
      >
        <img
          src="https://amplify-final-react-auth-dev-171957-deployment.s3.us-east-2.amazonaws.com/white-lcp-logo.png"
          alt="Leechiu Property Consultants"
          style={{
            width: "60%",
          }}
        />
      </div>
      <Container>
        <Button
          color="neutral"
          variant="contained"
          sx={{ color: "primary.main" }}
          fullWidth
          startIcon={<AddIcon />}
        >
           <Link
              to="/billing"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
          Billing Request
            </Link>
        </Button>
      </Container>
      <List>
        {items.map(({ title, icon, href }) => (
          <NavItem key={title} icon={icon} href={href} title={title} />
        ))}
      </List>
    </div>
  );

  useEffect(() => {
    async function fetchCurrentUser() {
      try {
        Auth.currentSession().then((data) => {
          let userData = data.getIdToken().decodePayload();
          setName(`${userData.given_name} ${userData.family_name}`);
        });
      } catch (error) {
        alert(`Failed fetching user: ${error}`);
      }
    }
    fetchCurrentUser();
  });

  async function signOut() {
    try {
      await Auth.signOut();
      window.localStorage.clear();
      addToken(initialState);
      navigate("/login");
    } catch (error) {
      alert(error);
    }
  }
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <AuthToolbar
          handleOpen={handleDrawerToggle}
          titleText={props.titleText}
          fullName={name}
          signOutFunc={signOut}
        />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            sx: {
              backgroundColor: "primary.main",
              color: "#FFFFFF",
            },
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: "primary.main",
              color: "#FFFFFF",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: "100px 25px 25px 25px",
          width: { xs: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
