import gql from "graphql-tag";

export const GETDEVELOPMENTS = gql`
  query GetDevelopments {
    developments: developments_schema_developments_copy {
      development_id
      development_name
    }
  }
`;

export const GETAUDITLOGS = gql`
  query MyQuery {
    audit_audit_view {
      action_tstamp_tx
      editor
      new_value
      old_column
      old_value
      transaction_id
      action_type
    }
  }
`;

export const ALLLEASECONTRACTS = gql`
  query AllLeaseContracts {
    commercial_leasing_lease_contracts {
      contact_person
      contact_number
      contact_email
      client_name
      fitout_period
      development_id
      development_data {
        development_name
      }
      escalation_rate
      year_applied
      gross_leasable_area_sqm
      industry
      lease_commencement_date
      lease_expiration_date
      net_usable_floor
      floor_number
      rent_per_sqm
      transaction_counterparty
      transaction_quarter
      transaction_year
      transaction_id
      lease_type
      peza_accreditation
      succeeding_escalation_rates
      advance_rent
      security_deposit
      reservation_deposit
      parking_rate
      vetting_fee
      common_area_maintenance_dues
      pre_termination
      broker_name
      parking_allocation
      contract_url
    }
  }
`;

export const ALLLEASECONTRACTSVIEW = gql`
  query LeaseContractsView {
    commercial_leasing_lease_contracts_view(order_by: { transaction_id: asc }) {
      contact_person
      contact_number
      contact_email
      transaction_id
      client_name
      development_name
      development_id
      developer
      floor_number
      fitout_period
      pretermination_date
      handover_date
      year_applied
      ultimate_developer
      escalation_rate
      gross_leasable_area_sqm
      industry
      lease_commencement_date
      lease_expiration_date
      monthly_rent
      net_usable_floor
      renewal_notice
      rent_per_sqm
      tenant
      transaction_counterparty
      transaction_quarter
      transaction_year
      lease_type
      peza_accreditation
      succeeding_escalation_rates
      advance_rent
      security_deposit
      reservation_deposit
      parking_rate
      vetting_fee
      common_area_maintenance_dues
      pre_termination
      broker_name
      parking_allocation
      district_name
      lease_term
      contract_url
    }
  }
`;

export const GETESCALATIONS = gql`
  query Escalations {
    commercial_leasing_escalations_view {
      client_name
      development_name
      escalation_date
      gross_leasable_area_sqm
      lease_commencement_date
      monthly_rent
      rent_esc_rate
      current_rent_sqm
      tenant
      transaction_id
      escalation_rate
    }
  }
`;

export const PREVIEWTABLE = gql`
  query PreviewTable(
    $district: String
    $todate: date
    $fromdate: date
    $transaction_type: String
    $search_query: String
    $broker: String
  ) {
    district_preview: ingest_wufoo_leasing_report_cleaned(
      where: {
        district_name: { _eq: $district }
        transaction_date: { _lte: $todate, _gte: $fromdate }
        transaction_type: { _eq: $transaction_type }
        assigned_broker: { _eq: $broker }
      }
      order_by: { id: asc }
    ) {
      id
      development_name
      district_name
      current_rent_sqm
      transaction_date
      gross_leasable_area_sqm
      industry
    }
    search_preview: ingest_wufoo_leasing_report_cleaned(
      where: {
        transaction_date: { _lte: $todate, _gte: $fromdate }
        transaction_type: { _eq: $transaction_type }
        development_name: { _eq: $search_query }
        assigned_broker: { _eq: $broker }
      }
      order_by: { id: asc }
    ) {
      id
      development_name
      district_name
      current_rent_sqm
      transaction_date
      gross_leasable_area_sqm
      industry
    }
  }
`;

export const DASHBOARDSTATS = gql`
  query DashboardStats(
    $district: String
    $todate: date
    $fromdate: date
    $transaction_type: String
    $broker: String
  ) {
    ingest_wufoo_leasing_report_cleaned_aggregate(
      where: {
        district_name: { _eq: $district }
        transaction_type: { _eq: $transaction_type }
        transaction_date: { _lte: $todate, _gte: $fromdate }
        assigned_broker: { _eq: $broker }
      }
    ) {
      aggregate {
        avg {
          current_rent_sqm
        }
        sum {
          gross_leasable_area_sqm
        }
      }
    }
    ingest_modal_lease_term(
      args: {
        from_date: $fromdate
        selected_city: $district
        to_date: $todate
        transaction: $transaction_type
        broker: $broker
      }
    ) {
      modal_value
    }
    ingest_modal_fitout_period(
      args: {
        from_date: $fromdate
        selected_city: $district
        to_date: $todate
        transaction: $transaction_type
        broker: $broker
      }
    ) {
      modal_value
    }
    ingest_deals_closed_quarterly(
      args: {
        from_date: $fromdate
        selected_city: $district
        to_date: $todate
        transaction: $transaction_type
        broker: $broker
      }
    ) {
      total_deals_closed
      transaction_quarter
      transaction_year
    }
    ingest_industry_percent_share(args: { selected_city: $district }) {
      city
      industry
      percent_share
    }
  }
`;

export const DASHBOARDSEARCHSTATS = gql`
  query DashboardSearchStats(
    $todate: date
    $fromdate: date
    $transaction_type: String
    $search_query: String
    $broker: String
  ) {
    ingest_wufoo_leasing_report_cleaned_aggregate(
      where: {
        transaction_type: { _eq: $transaction_type }
        transaction_date: { _lte: $todate, _gte: $fromdate }
        development_name: { _eq: $search_query }
        assigned_broker: { _eq: $broker }
      }
    ) {
      aggregate {
        avg {
          current_rent_sqm
        }
        sum {
          gross_leasable_area_sqm
        }
      }
    }
    ingest_modal_lease_term(
      args: {
        from_date: $fromdate
        to_date: $todate
        transaction: $transaction_type
        search_query: $search_query
        broker: $broker
      }
    ) {
      modal_value
    }
    ingest_modal_fitout_period(
      args: {
        from_date: $fromdate
        to_date: $todate
        transaction: $transaction_type
        search_query: $search_query
        broker: $broker
      }
    ) {
      modal_value
    }
    ingest_deals_closed_quarterly(
      args: {
        from_date: $fromdate
        to_date: $todate
        transaction: $transaction_type
        search_query: $search_query
        broker: $broker
      }
    ) {
      total_deals_closed
      transaction_quarter
      transaction_year
    }
    ingest_industry_percent_share(args: { search_query: $search_query }) {
      city
      industry
      percent_share
    }
  }
`;

export const FILTERDATA = gql`
  query FilterData {
    districts: ingest_wufoo_leasing_report_cleaned(distinct_on: district_name) {
      district_name
    }
    transaction_types: ingest_wufoo_leasing_report_cleaned(
      distinct_on: transaction_type
    ) {
      transaction_type
    }
    assigned_broker: ingest_wufoo_leasing_report_cleaned(
      distinct_on: assigned_broker
    ) {
      assigned_broker
    }
  }
`;

export const LEASINGREPORTVIEW = gql`
  query LeaseContractsView {
    commercial_leasing_leasing_report_view(order_by: { id: asc }) {
      id
      development_id
      development_name
      district_name
      industry
      client_name
      contact_person
      contact_email
      contact_number
      floor_number
      transaction_year
      fitout_period
      pretermination_date
      handover_date
      year_applied
      ultimate_developer
      escalation_rate
      escalation_rates_with_dates
      gross_leasable_area_sqm
      lease_commencement_date
      lease_expiration_date
      current_rent_sqm
      rent_per_sqm
      net_usable_floor
      renewal_notice
      tenant
      assigned_broker
      transaction_quarter
      lease_type
      peza_accreditation
      escalation_rate
      advance_rent
      security_deposit
      reservation_deposit
      parking_rate
      vetting_fee
      common_area_maintenance_dues
      pre_termination
      broker_name
      parking_slots
      developer
      lease_term
      contract_url
    }
  }
`;

export const LAZYMOREDETAILS = gql`
  query LazyMoreDetails($id: float8) {
    details: commercial_leasing_leasing_report_view(
      where: { id: { _eq: $id } }
    ) {
      development_id
      development_name
      id
      district_name
      developer
      industry
      client_name
      contact_person
      contact_email
      contact_number
      floor_number
      handover_date
      lease_term
      fitout_period
      lease_commencement_date
      renewal_notice
      lease_expiration_date
      pretermination_date
      pre_termination
      transaction_quarter
      year_applied
      escalation_rate
      gross_leasable_area_sqm
      current_rent_sqm
      rent_per_sqm
      assigned_broker
      broker_name
      lease_type
      peza_accreditation
      advance_rent
      security_deposit
      reservation_deposit
      parking_rate
      vetting_fee
      common_area_maintenance_dues
      parking_slots
    }
  }
`;
