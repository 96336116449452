import React, { useState } from "react";
import { Card, CardHeader, Container, Divider } from "@mui/material";
import { FormProvider } from "../../context";
import FirstPage from "./page1";
import LinearStepper from "./stepper";
import SecondPage from "./page2";
import ThirdPage from "./page3";
import FourthPage from "./page4";
import SummaryPage from "./summary";

export const MultiStepForm = () => {
  const [formStep, setFormStep] = useState<number>(0);

  const nextFormStep = () =>
    setFormStep((currentStep: number) => currentStep + 1);

  const prevFormStep = () =>
    setFormStep((currentStep: number) => currentStep - 1);
  return (
    <FormProvider>
      <LinearStepper formStep={formStep} setFormStep={setFormStep} />
      <Container maxWidth="sm">
        <Card>
          <CardHeader title="New Billing Request" />
          <Divider
            sx={{
              borderColor: "neutral.200",
            }}
          />
          <FirstPage
            formStep={formStep}
            prevFormStep={prevFormStep}
            nextFormStep={nextFormStep}
          />
          <SecondPage
            formStep={formStep}
            prevFormStep={prevFormStep}
            nextFormStep={nextFormStep}
          />
          <ThirdPage
            formStep={formStep}
            prevFormStep={prevFormStep}
            nextFormStep={nextFormStep}
          />
          <FourthPage
            formStep={formStep}
            prevFormStep={prevFormStep}
            nextFormStep={nextFormStep}
          />
          <SummaryPage
            formStep={formStep}
            prevFormStep={prevFormStep}
            nextFormStep={nextFormStep}
          />
        </Card>
      </Container>
    </FormProvider>
  );
};
