import { Line } from "react-chartjs-2";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import {
  Chart as ChartJS,
  CategoryScale,
  registerables,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ScatterDataPoint,
} from "chart.js";
import { useEffect, useState } from "react";
import { ChartData } from "chart.js";
import moment from "moment";

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const DealsClosedChart = (props: any) => {
  const {
    dealsClosedObject,
    districtName,
    searchQuery,
    searchType,
    fromDate,
    toDate,
  } = props;
  const [chartDataset, setChartDataset] = useState<any>({
    datasets: [],
    labels: [],
  });

  const quarterMap: { [key: number]: string } = {
    1: "1Q",
    2: "2Q",
    3: "3Q",
    4: "4Q",
  };
  const yearDataArray: { [key: number]: number[] } = [];
  var chartDatasetList: ChartData<
    "line",
    (number | ScatterDataPoint | null)[],
    unknown
  >[] = [];

  const yearsLabel: any = [
    ...new Set(dealsClosedObject.map((row: any) => row.transaction_year)),
  ];

  const quartersLabel: any = [
    ...new Set(
      dealsClosedObject.map((row: any) => quarterMap[row.transaction_quarter])
    ),
  ].sort();

  const chartColors = [
    "#3F51B5",
    "#e53935",
    "#FB8C00",
    "#53DB27",
    "#DB27C2",
    "#35E66D",
    "#1EE6CE",
    "#9C1DE6",
    "DC0BE6",
    "E6BC00",
  ];

  var options: any = {
    plugins: {
      title: {
        display: true,
        text: `${
          searchType === "district" ? districtName : searchQuery
        } transactions from ${moment(fromDate).format(
          "MMM DD, YYYY"
        )} to ${moment(toDate).format("MMM DD, YYYY")}`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  useEffect(() => {
    if (dealsClosedObject) {
      for (let i = 0; i < yearsLabel.length; i++) {
        const tempArray = dealsClosedObject
          .filter((row: any) => row.transaction_year === yearsLabel[i])
          .map((row: any) => row.total_deals_closed);
        yearDataArray[yearsLabel[i]] = tempArray;
        const tempChart: any = {
          borderColor: `${chartColors[i]}`,
          backgroundColor: `${chartColors[i]}`,
          barPercentage: 0.5,
          barThickness: 25,
          borderRadius: 4,
          categoryPercentage: 0.5,
          data: yearDataArray[yearsLabel[i]],
          label: `${yearsLabel[i]}`,
          maxBarThickness: 50,
          tension: 0.25,
        };
        chartDatasetList.push(tempChart);
      }

      setChartDataset({ datasets: chartDatasetList, labels: quartersLabel });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsClosedObject]);

  return (
    <Card {...props}>
      <CardHeader title="Transactions" />
      <Divider
        sx={{
          borderColor: "neutral.200",
        }}
      />
      <CardContent>
        <Box>
          <Line data={chartDataset} options={options} />
        </Box>
      </CardContent>
      <Divider
        sx={{
          borderColor: "neutral.200",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
        >
          Overview
        </Button>
      </Box>
    </Card>
  );
};
