import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Fade,
  Collapse,
  LinearProgress,
} from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { UsernamePasswordOpts } from "@aws-amplify/auth/lib-esm/types";
import { Alert, AlertTitle } from "@mui/material";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface ILoginUser {
  username: string;
  password: string;
}

const schema = yup.object().shape({
  username: yup
    .string()
    .required("Username is required"),
  password: yup
    .string()
    .required("Password is required")

});

export const Login = (props: any) => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<ILoginUser>({ resolver: yupResolver(schema) });
  const [errorMsg, handleError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const { addToken } = bindActionCreators(actionCreators, dispatch);

  async function signIn(ILoginUser: string | UsernamePasswordOpts) {
    try {
      setLoading(true);
      const user = await Auth.signIn(ILoginUser);
      const token = user.signInUserSession.idToken.jwtToken;
      addToken({
        token: token,
        isAuthenticated: true,
      });
      setLoading(false);
      navigate("/dashboard");
    } catch (error: any) {
      handleError(error.message);
      setLoading(false);
      setOpen(true);
    }
  }

  const onSubmit: SubmitHandler<ILoginUser> = async (
    datum: ILoginUser,
    e: any
  ) => {
    signIn(datum);
  };
  return (
    <>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "200ms" : "0ms",
        }}
        unmountOnExit
      >
        <LinearProgress color="primary" />
      </Fade>
      <Collapse in={open}>
        {!errorMsg ? null : (
          <Alert
            severity="error"
            onClose={() => {
              setOpen(false);
            }}
          >
            <AlertTitle>Error</AlertTitle>
            {errorMsg}
          </Alert>
        )}
      </Collapse>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "90vh",
          width: "calc(100%)",
        }}
      >
        <Card sx={{
           width: 300,
           margin: "0 auto",
           padding: 2
        }}>
          <form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <img
              src="https://amplify-final-react-auth-dev-171957-deployment.s3.us-east-2.amazonaws.com/logo-hires-transparent.png"
              alt="Leechiu Property Consultants"
              style={{
                display: "flex",
                margin: "0 auto",
                padding: 15,
                width: "50%",
              }}
            />
            <CardContent>
              <FormControl fullWidth>
                <TextField
                  id="standard-name"
                  label="Username"
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  sx={{
                    pb: 2
                  }}
                  {...register("username", { required: true })}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="standard-uncontrolled"
                  type="password"
                  label="Password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  sx={{
                    pb: 2
                  }}
                  {...register("password")}
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox name="checkedB" color="secondary" />}
                label="Remember me"
              />
            </CardContent>

            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Login
              </Button>
            </CardActions>
          </form>
          <Button color="primary" fullWidth>
            <Link
              to="/signup"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Sign Up
            </Link>
          </Button>
        </Card>
      </Grid>
    </>
  );
};
