import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, SelectChangeEvent } from "@mui/material";

interface Props {
  selectList: string[];
  selectedChoice: string;
  handleChange: (event: SelectChangeEvent) => void;
  selectLabel: string;
  disabool?: string;
}

export default function SelectFilter(props: Props) {
  const { selectList, selectedChoice, handleChange, selectLabel, disabool } =
    props;
  const toKebabCase = (str: any) =>
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x: any) => x.toLowerCase())
      .join("-");
  return (
    <Box
      sx={{
        ml: 1,
        mt: 2,
        mb: 2,
        mr: 1,
        display:
          disabool === "building name" && disabool !== undefined
            ? "none"
            : "flex",
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id={`${toKebabCase(selectLabel)}-label`}>
          {selectLabel}
        </InputLabel>
        <Select
          labelId={`${toKebabCase(selectLabel)}-label`}
          id={`${toKebabCase(selectLabel)}-select`}
          value={selectedChoice}
          label={selectLabel}
          onChange={handleChange}
        >
          {selectList.map((name: string) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
