import React, { useEffect, useState } from "react";
import { GETAUDITLOGS } from "../API/queries";
import { useQuery } from "@apollo/client";
import { Column } from "../utils/types";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import { Avatar, Grid, TableCell, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import moment from "moment";
import { Auth } from "aws-amplify";

type Table = {
  columns: Column[];
  rows: auditData[];
};
type auditData = {
  editor: string | null;
  action_type: string;
  action_tstamp_tx: Date;
  transaction_id: string | null;
  old_column: string | null;
  old_value: string | null;
  new_value: string | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "70vh",
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    containerSpacing: {
      padding: "30px 0",
    },
  })
);

export const AuditTable = () => {
  const {
    error: errorAudit,
    data: dataAudit,
    refetch: refetchAudit,
  } = useQuery(GETAUDITLOGS);

  const [fullName, setName] = useState("");
  const [auditData, setData] = useState<Table>({
    columns: [
      {
        field: "editor",
        headerName: "Editor",
        type: "string",
        width: 120,
      },
      {
        field: "action_type",
        headerName: "Action Type",
        type: "string",
        width: 150,
      },
      {
        field: "action_tstamp_tx",
        headerName: "Action Timestamp",
        type: "date",
        width: 250,
        renderCell: (params: any) => {
          return (
            <TableCell align="left">
              {!params.value
                ? null
                : moment(params.value).format("MMM DD, YYYY HH:MM:SS")}
            </TableCell>
          );
        },
      },
      {
        field: "transaction_id",
        headerName: "Transaction ID",
        type: "string",
        width: 250,
      },
      {
        field: "old_column",
        headerName: "Column Changed",
        type: "string",
        width: 250,
      },
      {
        field: "old_value",
        headerName: "Old Value",
        type: "string",
        width: 250,
      },
      {
        field: "new_value",
        headerName: "New Value",
        type: "string",
        width: 250,
      },
    ],
    rows: [],
  });
  useEffect(() => {
    if (dataAudit) {
      const tableData = dataAudit.audit_audit_view.map(
        (o: any, idx: number) => ({ id: idx + 1, ...o })
      );

      setData({
        columns: auditData.columns,
        rows: tableData,
      });
      Auth.currentSession().then((data) => {
        let userData = data.getIdToken().decodePayload();
        setName(`${userData.given_name} ${userData.family_name}`);
      });
    }
  }, [dataAudit]);

  const rows: GridRowsProp = auditData.rows;
  const columns: GridColDef[] = auditData.columns;

  return (
    <div style={{ height: 500, width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Grid item md={2}>
              <Avatar
                src="/broken-image.jpg"
                sx={{ width: 128, height: 128 }}
              />
            </Grid>
            <Grid item md={4}>
              <Typography variant="h3" gutterBottom>
                {fullName}
              </Typography>
            </Grid>
          </Grid>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </div>
    </div>
  );
};
