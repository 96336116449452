import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IPreviewData } from "../../utils/types";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { LAZYMOREDETAILS } from "../../API/queries";
import { MoreDetailsModal } from "./more-details-modal";

export default function DisplayTable(props: any) {
  const { previewDataObject } = props;
  const [open, setOpen] = useState(false);
  // const [id, setId] = useState<Number>();
  const [getMoreDetails, { error, loading, data }] =
    useLazyQuery(LAZYMOREDETAILS);

  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Button onClick={handleModal} startIcon={<ManageSearchRoundedIcon />}>
        Preview Table
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
            }}
          >
            <CardHeader title="Preview Table" />
            <CardContent>
              <TableContainer
                sx={{
                  overflow: "auto",
                  height: "50vh",
                }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="right">Building Name</TableCell>
                      <TableCell align="right">District</TableCell>
                      <TableCell align="right">Current Rent per SQM</TableCell>
                      <TableCell align="right">Transaction Date</TableCell>
                      <TableCell align="right">Gross Leasable Area</TableCell>
                      <TableCell align="right">Industry</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {previewDataObject?.map((row: IPreviewData) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="right">
                          {row.development_name}
                        </TableCell>
                        <TableCell align="right">{row.district_name}</TableCell>
                        <TableCell align="right">
                          {row.current_rent_sqm?.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          {moment(row.transaction_date).format("MMM DD, YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          {row.gross_leasable_area_sqm?.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">{row.industry}</TableCell>
                        <TableCell align="right">
                          <MoreDetailsModal id={row.id}>View</MoreDetailsModal>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="contained" color="primary" onClick={handleModal}>
                Exit
              </Button>
            </CardActions>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}
