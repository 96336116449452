// import styles from "../../styles/styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import { useFormData } from "../../context";
import { Data } from "../../utils/types";
import {
  Grid,
  FormControl,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CardActions,
  CardContent,
  Paper,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";

export default function SecondPage(props: any) {
  const { formStep, prevFormStep, nextFormStep } = props;
  const { data, setFormValues } = useFormData();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    control,
  } = useForm<Data>();

  const onSubmit = (values: Data) => {
    setFormValues(values);
    nextFormStep();
    console.log(data);
  };

  const useOfSpace = [
    "Residential",
    "BPO",
    "POGO",
    "Office",
    "Retail",
    "Warehouse",
    "Investment",
  ];

  return (
    <Paper
      sx={{
        display: formStep === 1 ? "flex" : "none",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2} justifyContent="space-evenly">
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Transaction Quarter"
                      type="number"
                      error={!!errors.transaction_quarter}
                      helperText={errors.transaction_quarter?.message}
                      inputProps={{ min: 1, max: 4 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                  name="transaction_quarter"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Transaction Year"
                      type="number"
                      error={!!errors.transaction_year}
                      helperText={errors.transaction_year?.message}
                      inputProps={{ min: 2000, max: 2022 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={2000}
                      {...field}
                    />
                  )}
                  name="transaction_year"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="standard-rent-per-sqm"
                      label="Monthly rent per SQM"
                      type="number"
                      error={!!errors.rent_per_sqm}
                      helperText={errors.rent_per_sqm?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                  name="rent_per_sqm"
                  control={control}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <InputLabel id="use_of_space_label">
                        Use of Space
                      </InputLabel>
                      <Select
                        labelId="use_of_space_label"
                        id="select_use_of_space"
                        error={!!errors.use_of_space}
                        label="Use of Space"
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {useOfSpace.map((label: string) => (
                          <MenuItem value={label}>{label}</MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  name="use_of_space"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-renewal-notice"
                      error={!!errors.renewal_notice}
                      label="Renewal Notice"
                      helperText="in days"
                      {...field}
                    />
                  )}
                  name="renewal_notice"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="standard-fitout-period"
                      label="Fit-out Period"
                      helperText="in days"
                      error={!!errors.fitout_period}
                      inputProps={{ min: 0 }}
                      type="number"
                      {...field}
                    />
                  )}
                  name="fitout_period"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-pre-termination"
                      label="Pre-termination"
                      type="number"
                      helperText="in months"
                      error={!!errors.pre_termination}
                      inputProps={{ min: 0 }}
                      {...field}
                    />
                  )}
                  name="pre_termination"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <InputLabel htmlFor="standard-escalation-rate">
                        Escalation Rate
                      </InputLabel>
                      <OutlinedInput
                        id="standard-escalation-rate"
                        error={!!errors.escalation_rate}
                        label="Escalation Rate"
                        type="number"
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                          min: 0,
                          max: 100,
                        }}
                        {...field}
                      />
                    </>
                  )}
                  name="escalation_rate"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="standard-year-applied"
                      label="Year Applied"
                      error={!!errors.year_applied}
                      type="number"
                      inputProps={{ min: 0, max: 5 }}
                      {...field}
                    />
                  )}
                  name="year_applied"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-parking-allocation"
                      label="Parking Slots"
                      helperText={errors.parking_allocation?.message}
                      type="number"
                      error={!!errors.parking_allocation}
                      {...field}
                    />
                  )}
                  name="parking_allocation"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-parking-rate"
                      label="Parking Rate"
                      type="number"
                      helperText="Per Slot Per Month"
                      error={!!errors.parking_rate}
                      {...field}
                    />
                  )}
                  name="parking_rate"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-advance-rent"
                      label="Advance Rent"
                      type="number"
                      helperText="(in Months)"
                      error={!!errors.advance_rent}
                      {...field}
                    />
                  )}
                  name="advance_rent"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-security-deposit"
                      label="Security Deposit"
                      type="number"
                      helperText="(in Months)"
                      error={!!errors.security_deposit}
                      {...field}
                    />
                  )}
                  name="security_deposit"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-common-area-maintenance-dues"
                      label="Building Dues"
                      helperText="per SQM"
                      error={!!errors.common_area_maintenance_dues}
                      type="number"
                      {...field}
                    />
                  )}
                  name="common_area_maintenance_dues"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-reservation-deposit"
                      label="Reservation Deposit"
                      helperText="(in Months)"
                      type="number"
                      error={!!errors.reservation_deposit}
                      {...field}
                    />
                  )}
                  name="reservation_deposit"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-vetting-fee"
                      label="Vetting Fee"
                      type="number"
                      helperText="per SQM"
                      error={!!errors.vetting_fee}
                      {...field}
                    />
                  )}
                  name="vetting_fee"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Settled Required PDCs?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        {...field}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio />}
                          label="Not Applicable"
                        />
                      </RadioGroup>
                    </>
                  )}
                  name="settled_required_pdcs"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={prevFormStep}>back</Button>
          <Button type="submit">Next</Button>
        </CardActions>
      </form>
    </Paper>
  );
}
