// import styles from "../../styles/styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import { useFormData } from "../../context";
import { Data } from "../../utils/types";
import {
  Grid,
  FormControl,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CardActions,
  CardContent,
  Paper,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function ThirdPage(props: any) {
  const { formStep, prevFormStep, nextFormStep } = props;
  const { data, setFormValues } = useFormData();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    control,
  } = useForm<Data>();

  const onSubmit = (values: Data) => {
    setFormValues(values);
    nextFormStep();
    console.log(data);
  };

  return (
    <Paper
      sx={{
        display: formStep === 2 ? "flex" : "none",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2} justifyContent="space-evenly">
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Entity to be Billed"
                      error={!!errors.entity_billed}
                      helperText={errors.entity_billed?.message}
                      {...field}
                    />
                  )}
                  name="entity_billed"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Billing Address"
                      error={!!errors.billing_address}
                      helperText={errors.billing_address?.message}
                      {...field}
                    />
                  )}
                  name="billing_address"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-contact-person"
                      label="Contact Person"
                      error={!!errors.contact_person}
                      {...field}
                    />
                  )}
                  name="contact_person"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-contact-number"
                      label="Contact Number"
                      {...field}
                    />
                  )}
                  name="contact_number"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-contact-email"
                      label="Contact E-mail"
                      {...field}
                    />
                  )}
                  name="contact_email"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-number-of-months-commission"
                      label="No. of Months Commission"
                      type="number"
                      error={!!errors.number_of_months_commission}
                      helperText={errors.number_of_months_commission?.message}
                      {...field}
                    />
                  )}
                  name="number_of_months_commission"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <InputLabel id="lpc_service_label">
                        LPC Service
                      </InputLabel>
                      <Select
                        labelId="lpc_service_label"
                        id="select_lpc_service"
                        error={!!errors.lpc_service}
                        label="LPC Service"
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {/* {brokerList.map((broker: string) => (
                          <MenuItem value={broker}>{broker}</MenuItem>
                        ))} */}
                      </Select>
                    </>
                  )}
                  name="lpc_service"
                  control={control}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-vat-amount"
                      label="VAT Amount"
                      helperText="in PHP"
                      type="number"
                      error={!!errors.vat_amount}
                      {...field}
                    />
                  )}
                  name="vat_amount"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-amount-billed"
                      label="Billed Amount"
                      helperText="in PHP"
                      type="number"
                      error={!!errors.amount_billed}
                      {...field}
                    />
                  )}
                  name="amount_billed"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="component-total-invoice-amount"
                      label="Total Invoice Amount"
                      helperText="in PHP"
                      type="number"
                      error={!!errors.total_invoice_amount}
                      {...field}
                    />
                  )}
                  name="total_invoice_amount"
                  control={control}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={prevFormStep}>back</Button>
          <Button type="submit">Next</Button>
        </CardActions>
      </form>
    </Paper>
  );
}
