import { Controller, useForm } from "react-hook-form";
import { useFormData } from "../../context";
import { Data, Development } from "../../utils/types";
import {
  Grid,
  FormControl,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CardActions,
  CardContent,
  Paper,
  Autocomplete,
  createFilterOptions,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DialogFields } from "../../old-pages/DialogFields";
import { useQuery } from "@apollo/client";
import { GETDEVELOPMENTS } from "../../API/queries";

export default function FirstPage(props: any) {
  const { formStep, nextFormStep } = props;
  const { setFormValues } = useFormData();
  const [openDialog, setOpenDialog] = useState(false);
  const [devName, setDevName] = useState("");

  const brokerList: { [key: string]: any } = {
    "Abigail Obias": "abby.obias@leechiu.com",
    "Aldrin Tayag": "aldrin.tayag@leechiu.com",
    "Allan Paul Turalba": "paul.turalba@leechiu.com",
    "Alvin Magat": "alvin.magat@leechiu.com",
    "Angela Jimenez": "angel.jimenez@leechiu.com",
    "Arielle Roleda": "arielle.roleda@leechiu.com",
    "Bobby A. Apas": "bobby.apas@leechiu.com",
    "Charlene Blanche B. Jabson": "charlene.jabson@leechiu.com",
    "Christopher Dela Rosa": "christopher.delarosa@leechiu.com",
    "David Leechiu": "david@leechiu.com",
    "Edmon Sapalasan": "edmon.sapalasan@leechiu.com",
    "Eduardo Castillo": "eduardo.castillo@leechiu.com",
    "Edward Charles Gador": "ed.gador@leechiu.com",
    "Erika Recomite-Manasan": "erika.manasan@leechiu.com",
    "Erwin De Jesus": "erwin.dejesus@leechiu.com",
    "Francis Manuel": "francis.manuel@leechiu.com",
    "Gilbert John Dayao": "gilbert.dayao@leechiu.com",
    "Henrik Roston Uy": "henrik.uy@leechiu.com",
    "Henry Cabrera": "henry.cabrera@leechiu.com",
    "Jamella Narciso": "jamella.narciso@leechiu.com",
    "James Manasan": "james.manasan@leechiu.com",
    "James Ryan Dolina": "jamesryan.dolina@leechiu.com",
    "Jana Rose Aniban": "jana.aniban@leechiu.com",
    "Jeff Ocampo": "jeff.ocampo@leechiu.com",
    "Jeo Bantigue ": "jeo.bantigue@leechiu.com",
    "Jessa Lopez": "jessa.lopez@leechiu.com",
    "Jo Abellanosa": "jo.abellanosa@leechiu.com",
    "John Benedict Flores": "benedict.flores@leechiu.com",
    "Jose Gutierrez": "joey.gutierrez@leechiu.com",
    "Joseph Macaranas": "joseph.macaranas@leechiu.com",
    "Jovalyn Buella": "jovalyn.buella@leechiu.com",
    "Joy Lopez": "joy.lopez@leechiu.com",
    "Joy Raah Serrano": "joyraah.serrano@leechiu.com",
    "Juan Edmundo Reyes": "juanedmundo.reyes@leechiu.com",
    "Juan Miguel Natividad": "jm.natividad@leechiu.com",
    "Kate Canizares": "kate.canizares@leechiu.com",
    "Katherine Elisse Ignacio": "katherine.ignacio@leechiu.com",
    "Kattrice Perez": "kattrice.perez@leechiu.com",
    "Lylah Ledonio": "lylah.ledonio@leechiu.com",
    "Marco De Padua": "marco.depadua@leechiu.com",
    "Maricar Masangkay": "maricar.masangkay@leechiu.com",
    "Michael Basco": "michael.basco@leechiu.com",
    "Michael Guzman": "michael.guzman@leechiu.com",
    "Michael Tuazon": "michael.tuazon@leechiu.com",
    "Migs Paggabao": "migs.paggabao@leechiu.com",
    "Miguel Manipol": "miguel.manipol@leechiu.com",
    "Mikko Barranda": "mikko.barranda@leechiu.com",
    "Phillip Anonuevo": "phillip.anonuevo@leechiu.com",
    "Rachel Hannah Hahn": "rachel.hahn@leechiu.com",
    "Ralph Dela Cruz": "ralph.delacruz@leechiu.com",
    "RG Santos": "rg.santos@leechiu.com",
    "RJ Domingo": "rj.domingo@leechiu.com",
    "Roy Golez": "roy.golez@leechiu.com",
    "Stacy Palad": "stacy.palad@leechiu.com",
    "Stephanie Pastor": "phanie.pastor@leechiu.com",
    "Sven Jericho  Dayola": "sven.dayola@leechiu.com",
    "Tam Angel": "tam.angel@leechiu.com",
    "Tina Cornejo": "tina.cornejo@leechiu.com",
    "Tomas Joaquin Reyes": "tomas.reyes@leechiu.com",
    "Tristan Joseph Gaba": "tristan.gaba@leechiu.com",
    "Wilvic Gacita": "wilvic.gacita@leechiu.com",
    "Yvette Atotubo": "yvette.atotubo@leechiu.com",
  };

  const leaseTypeList = [
    "1st Site",
    "Consolidation",
    "Expansion",
    "Pre-termination",
    "Relocation & Expansion",
    "Renewal",
    "Renewal/Relocation",
    "Seat Leasing",
    "Htl Mgt",
    "Htl Mgt/JV",
    "JV",
    "Land Lease",
    "Sale",
    "Sale & Lease",
    "Sale/JV",
    "Sale/Lease",
  ];

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
  } = useForm<Data>();
  const {
    error,
    data: devData,
    refetch: refetchDevelopments,
  } = useQuery(GETDEVELOPMENTS);
  const filter = createFilterOptions<Development>();
  const [developmentList, setList] = useState<Array<Development>>([]);

  const options = developmentList.map((option: Development) => {
    const firstLetter = option.development_name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const onSubmit = (values: Data) => {
    setFormValues(values);
    nextFormStep();
    console.log(values);
  };

  const handleDialogClose = () => {
    setDevName("");
    refetchDevelopments();
    setOpenDialog(false);
  };

  useEffect(() => {
    if (devData) {
      setList(devData.developments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devData]);

  return (
    <Paper
      sx={{
        display: formStep === 0 ? "flex" : "none",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      freeSolo
                      id="grouped-demo"
                      autoHighlight
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option: Development) => option.firstLetter}
                      getOptionLabel={(option: string | Development) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.development_name;
                      }}
                      filterOptions={(options: any, params: any) => {
                        const filtered = filter(
                          options,
                          params
                        ) as Development[];

                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: `Add "${params.inputValue}"`,
                            development_name: params.inputValue,
                          });
                        }

                        return filtered;
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} label="Development Name" />
                      )}
                      onChange={(_, data: any) => {
                        if (data?.inputValue) {
                          setDevName(data?.development_name);
                          setOpenDialog(true);
                        } else {
                          onChange(data?.development_id);
                        }
                      }}
                    />
                  )}
                  name="development_id"
                  control={control}
                  defaultValue=""
                />
                <DialogFields
                  open={openDialog}
                  handleClose={handleDialogClose}
                  developmentName={devName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <InputLabel id="lease-type-label">Lease Type</InputLabel>
                      <Select
                        labelId="lease-type-label"
                        id="lease-type-select-2"
                        label="Lease Type"
                        error={!!errors.lease_type}
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {leaseTypeList.map((lease_type: string) => (
                          <MenuItem value={lease_type}>{lease_type}</MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  name="lease_type"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Tenant"
                      error={!!errors.client_name}
                      helperText={errors.client_name?.message}
                      {...field}
                    />
                  )}
                  name="client_name"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Source of Lead
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        {...field}
                      >
                        <FormControlLabel
                          value="web"
                          control={<Radio />}
                          label="Web"
                        />
                        <FormControlLabel
                          value="referral"
                          control={<Radio />}
                          label="Referral"
                        />
                        <FormControlLabel
                          value="others"
                          control={<Radio />}
                          label="Others (please specify)"
                        />
                      </RadioGroup>
                    </>
                  )}
                  name="lead_source"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="date-input-1"
                      label="Commencement Date"
                      type="date"
                      error={!!errors.lease_commencement_date}
                      helperText={errors.lease_commencement_date?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                  name="lease_commencement_date"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="date-input-2"
                      label="Expiration Date"
                      type="date"
                      error={!!errors.lease_expiration_date}
                      helperText={errors.lease_expiration_date?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                  name="lease_expiration_date"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Floor Number"
                      error={!!errors.floor_number}
                      helperText={errors.floor_number?.message}
                      {...field}
                    />
                  )}
                  name="floor_number"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="standard-gross-leasable-area"
                      label="Gross Leasable Area"
                      type="number"
                      helperText="in SQM"
                      error={!!errors.gross_leasable_area_sqm}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                  name="gross_leasable_area_sqm"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <InputLabel id="assigned_broker_1_label">
                        Assigned Broker
                      </InputLabel>
                      <Select
                        labelId="assigned_broker_1_label"
                        id="select_ab2"
                        label="Assigned Broker"
                        error={!!errors.transaction_counterparty}
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {Object.keys(brokerList).map((broker: string) => (
                          <MenuItem value={brokerList[`${broker}`]}>
                            {broker}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  name="transaction_counterparty"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <InputLabel id="assigned_broker_2_label">
                        Assigned Broker 2
                      </InputLabel>
                      <Select
                        labelId="assigned_broker_2_label"
                        id="select_ab2"
                        label="Assigned Broker 2"
                        error={!!errors.assigned_broker_2}
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {Object.keys(brokerList).map((broker: string) => (
                          <MenuItem value={brokerList[`${broker}`]}>
                            {broker}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  name="assigned_broker_2"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <InputLabel id="assigned_broker_3_label">
                        Assigned Broker 3
                      </InputLabel>
                      <Select
                        labelId="assigned_broker_3_label"
                        id="select_ab3"
                        error={!!errors.assigned_broker_3}
                        label="Assigned Broker 3"
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {Object.keys(brokerList).map((broker: string) => (
                          <MenuItem value={brokerList[`${broker}`]}>
                            {broker}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  name="assigned_broker_3"
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  render={({ field }) => (
                    <>
                      <InputLabel id="assigned_broker_4_label">
                        Assigned Broker 4
                      </InputLabel>
                      <Select
                        labelId="assigned_broker_4_label"
                        id="select_ab4"
                        error={!!errors.assigned_broker_4}
                        label="Assigned Broker 4"
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {Object.keys(brokerList).map((broker: string) => (
                          <MenuItem value={brokerList[`${broker}`]}>
                            {broker}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  name="assigned_broker_4"
                  control={control}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button type="submit">Next</Button>
        </CardActions>
      </form>
    </Paper>
  );
}
