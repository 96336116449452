import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Popover } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

type EscalationData = {
  renewal_term: string;
  monthly_rent: number;
  percent_increase: number;
};

function createData(row: EscalationData) {
  return row;
}

var rows: EscalationData[] = [];

export const ModalTable = (props: any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [commencementDate, setCommencementDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [escalationRate, setEscalationRate] = useState<number>(0);
  const [rentPerSQM, setRentPerSQM] = useState<number>(0);
  const [yearApplied, setYearApplied] = useState<number>(0);
  const [yearsWithInterest, setYearsWithInterest] = useState<number>(0);
  useEffect(() => {
    try {
      setCommencementDate(props.commencement);
      setExpirationDate(props.expiration);
      setEscalationRate(props.escalation);
      setYearApplied(props.year_applied);
      setRentPerSQM(props.rent_per_sqm);
      setYearsWithInterest(
        Math.ceil(
          moment(props.expiration).diff(
            moment(props.commencement).add(props.year_applied, "y"),
            "years",
            true
          )
        )
      );
    } catch (err: any) {
      alert(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAnchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (yearApplied) {
      var year_iterator = 1;
      while (year_iterator <= yearApplied) {
        const year_from = moment(commencementDate)
          .add(year_iterator - 1, "y")
          .format("MM/DD/YYYY");
        const year_to = moment(commencementDate)
          .add(year_iterator, "y")
          .format("MM/DD/YYYY");
        const term = `${year_from} - ${year_to}`;
        rows.push(
          createData({
            renewal_term: term,
            monthly_rent: rentPerSQM,
            percent_increase: 0,
          })
        );
        year_iterator++;
      }

      for (let i = 1; i <= yearsWithInterest; i++) {
        if (i === yearsWithInterest) {
          const year_from = moment(commencementDate)
            .add(yearApplied + (i - 1), "y")
            .format("MM/DD/YYYY");
          const year_to = moment(expirationDate).format("MM/DD/YYYY");
          const new_term = `${year_from} - ${year_to}`;
          const percentage = 1 + escalationRate * 0.01;
          rows.push(
            createData({
              renewal_term: new_term,
              monthly_rent:
                Math.round(rentPerSQM * Math.pow(percentage, i) * 100) / 100, // monthlyRent*(1 + (escalationRate*0.01))^(t)
              percent_increase: escalationRate,
            })
          );
        } else {
          const year_from = moment(commencementDate)
            .add(yearApplied + (i - 1), "y")
            .format("MM/DD/YYYY");
          const year_to = moment(commencementDate)
            .add(yearApplied + i, "y")
            .format("MM/DD/YYYY");
          const new_term = `${year_from} - ${year_to}`;
          const percentage = 1 + escalationRate * 0.01;
          rows.push(
            createData({
              renewal_term: new_term,
              monthly_rent:
                Math.round(rentPerSQM * Math.pow(percentage, i) * 100) / 100, // monthlyRent*(1 + (escalationRate*0.01))^(t)
              percent_increase: escalationRate,
            })
          );
        }
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    rows = [];
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      {/* 
    
    get monthly rent
    get lease commencement date
    get year applied
    
    */}
      <Button
        aria-describedby={id}
        variant="text"
        color="primary"
        onClick={handleClick}
        disabled={!!!yearApplied}
      >
        {props.children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Lease Term</TableCell>
                <TableCell align="right">Rent (PHP/SQM/mo)</TableCell>
                <TableCell align="right">% Increase</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" align="right">
                    {row.renewal_term}
                  </TableCell>
                  <TableCell align="right">{row.monthly_rent}</TableCell>
                  <TableCell align="right">{row.percent_increase}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    </>
  );
};
