import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { initialState, superState } from "../reducers/tokenStore";
import { dataState } from "../reducers/checkedRowStore";
import { Data } from "../../utils/types";
const addToken = (state: superState = initialState) => {
  return (dispatch: Dispatch<Action>) =>
    dispatch({
      type: ActionType.ADDTOKEN,
      payload: state,
    });
};

const addCheckedRow = (state: Data = dataState) => {
  return (dispatch: Dispatch<Action>) =>
    dispatch({
      type: ActionType.ADDCHECKEDROW,
      payload: state,
    });
};

export { addToken, addCheckedRow };
