import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Box, Button, Menu, MenuItem } from "@mui/material";

export default function AuthToolbar(props: any) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  return (
    <Toolbar
      sx={{
        backgroundColor: "white",
        color: "primary.main",
      }}
    >
      <IconButton
        aria-label="open drawer"
        edge="start"
        onClick={props.handleOpen}
        sx={{ mr: 2, display: { md: "none" }, color: "neutral.700" }}
      >
        <MenuIcon />
      </IconButton>

      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {props.titleText}
      </Typography>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          sx={{
            color: "neutral.600",
          }}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleAnchorClose}
        >
          <MenuItem onClick={handleAnchorClose}>Profile</MenuItem>
          <MenuItem onClick={handleAnchorClose}>My account</MenuItem>
        </Menu>
      </Box>
      <Typography
        color="textPrimary"
        sx={{ display: { xs: "none", md: "block" } }}
      >
        {props.fullName.toUpperCase()} |
        <Button
          variant="text"
          style={{ color: "inherit", textDecoration: "inherit" }}
          onClick={props.signOutFunc}
        >
          Sign Out
        </Button>
      </Typography>
    </Toolbar>
  );
}
