import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { FormControl } from "@mui/material";
import { ADDNEWDEVELOPMENT } from "../API/mutations";
import { useMutation } from "@apollo/client";

type Dev = {
  development_name: string;
  location: string;
};
export const DialogFields = (props: any) => {
  const { register, handleSubmit, reset } = useForm<Dev>();
  const [addDevelopment, { loading, error }] = useMutation(ADDNEWDEVELOPMENT);
  const onSubmit: SubmitHandler<Dev> = async (datum: Dev, e: any) => {
    await addDevelopment({ variables: datum });
    if (!error) {
      reset();
      props.handleClose();
    } else {
      alert(error);
    }
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            Add a new development
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any development in our list? Please, add it!
            </DialogContentText>
            <FormControl>
              <TextField
                autoFocus
                margin="dense"
                label="Development Name"
                defaultValue={props.developmentName}
                {...register("development_name")}
              />
            </FormControl>
            <FormControl>
              <TextField
                autoFocus
                margin="dense"
                label="Location"
                {...register("location")}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
