import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  createFilterOptions,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { TotalLeaseArea } from "./total-lease";
import { AverageRentSQM } from "./average-rent-sqm";
import { ModeLeaseTerm } from "./mode-lease-term";
import { DealsClosedChart } from "./deals-closed-chart";
import { TrafficByDevice } from "./pie-chart";
import { ModeFitoutPeriod } from "./mode-fitout-period";
import { useQuery } from "@apollo/client";
import {
  DASHBOARDSEARCHSTATS,
  DASHBOARDSTATS,
  FILTERDATA,
  GETDEVELOPMENTS,
  PREVIEWTABLE,
} from "../../API/queries";
import { useEffect, useState, useCallback, useRef } from "react";

// date pickers
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DisplayTable from "./display-table";
import SelectFilter from "./filter-component";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Development,
  IAvgData,
  IDealsData,
  IPercentShareData,
  IPreviewData,
} from "../../utils/types";
import { ConsoleLogger } from "@aws-amplify/core";

const filter = createFilterOptions<Development>();

const devTypeList = ["Office", "Retail"];
export const Dashboard = () => {
  const componentRef = useRef(null);

  const [district, setDistrict] = useState<string>("Makati");
  const [transactionType, setTransactionType] = useState<string>("Office");
  const [assignedBroker, setAssignedBroker] = useState<string>("");

  const [fromDate, setFromDate] = useState<Date | null>(new Date("2019-01-01"));
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [searchType, setSearchType] = useState<string>("district");
  const [autocomplete, setAutocomplete] = useState<any>("");
  const [InputValue, setInputValue] = useState<any>("");

  const { data: dashboardData } = useQuery(DASHBOARDSTATS, {
    variables: {
      fromdate: fromDate,
      todate: toDate,
      district: district,
      transaction_type: transactionType,
      broker: assignedBroker,
    },
  });
  const { data: dashboardSearchData } = useQuery(DASHBOARDSEARCHSTATS, {
    variables: {
      fromdate: fromDate,
      todate: toDate,
      search_query: autocomplete,
      transaction_type: transactionType,
      broker: assignedBroker,
    },
  });
  const { data: filterOptions } = useQuery(FILTERDATA);
  const { data: previewTableData } = useQuery(PREVIEWTABLE, {
    variables: {
      fromdate: fromDate,
      todate: toDate,
      district: district,
      transaction_type: transactionType,
      search_query: autocomplete,
      broker: assignedBroker,
    },
  });

  const handleSearchType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchType((event.target as HTMLInputElement).value);
  };

  const [districtList, setDistrictList] = useState<string[]>([]);
  const [brokerList, setBrokerList] = useState<string[]>([]);
  const [transactionTypeList, setTransactionTypeList] = useState<string[]>([]);

  const [percentShare, setPercentShare] = useState<IPercentShareData[]>([]);
  const [dealsClosed, setDealsClosed] = useState<IDealsData[]>([]);
  const [previewDataState, setPreviewData] = useState<IPreviewData[]>();
  const handleDistrict = (event: SelectChangeEvent) => {
    setDistrict(event.target.value as string);
  };
  const handleTransactionType = (event: SelectChangeEvent) => {
    setTransactionType(event.target.value as string);
  };
  const handleBroker = (event: SelectChangeEvent) => {
    setAssignedBroker(event.target.value as string);
  };

  const [avgDataState, setAvgData] = useState<IAvgData>({
    current_rent_sqm: 0,
    lease_term: 0,
    fitout_period: 0,
  });

  const { data: devData } = useQuery(GETDEVELOPMENTS);

  const [developmentList, setList] = useState<Array<Development>>([]);
  const options = developmentList.map((option: Development) => {
    const firstLetter = option.development_name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const [totalLeaseArea, setTotalLeaseArea] = useState<number>(0);

  // get quarter of from_date
  // if from_date quarter === to_date quarter and from_date year === to_date year
  // get same quarter from previous years
  // if from_date quarter !== to_date quarter and from_date year === to_date year
  // get
  useEffect(() => {
    try {
      if (filterOptions) {
        const districtArray = filterOptions.districts.map(
          (row: any) => row.district_name
        );
        const transactionTypeArray = filterOptions.transaction_types.map(
          (row: any) => row.transaction_type
        );
        const brokerArray = filterOptions.assigned_broker.map(
          (row: any) => row.assigned_broker
        );
        setDistrictList(districtArray);
        setTransactionTypeList(transactionTypeArray);
        setBrokerList(brokerArray);
      }

      if (devData) {
        setList(devData.developments);
      }

      if (dashboardData && searchType === "district") {
        setAvgData({
          current_rent_sqm:
            dashboardData.ingest_wufoo_leasing_report_cleaned_aggregate
              .aggregate.avg.current_rent_sqm,
          lease_term: dashboardData.ingest_modal_lease_term[0].modal_value,
          fitout_period:
            dashboardData.ingest_modal_fitout_period[0].modal_value,
        });
        setTotalLeaseArea(
          dashboardData.ingest_wufoo_leasing_report_cleaned_aggregate.aggregate
            .sum.gross_leasable_area_sqm
        );
        const previewArray = previewTableData.district_preview.map(
          (row: any) => row
        );
        setPreviewData(previewArray);

        const dealsClosedFull = dashboardData.ingest_deals_closed_quarterly.map(
          (row: any) => row
        );
        const percentShareFull =
          dashboardData.ingest_industry_percent_share.map((row: any) => row);

        setPercentShare(percentShareFull);
        setDealsClosed(dealsClosedFull);
      }
      if (dashboardSearchData && searchType === "building name") {
        setAvgData({
          current_rent_sqm:
            dashboardSearchData.ingest_wufoo_leasing_report_cleaned_aggregate
              .aggregate.avg.current_rent_sqm,
          lease_term:
            dashboardSearchData.ingest_modal_lease_term[0].modal_value,
          fitout_period:
            dashboardSearchData.ingest_modal_fitout_period[0].modal_value,
        });
        setTotalLeaseArea(
          dashboardSearchData.ingest_wufoo_leasing_report_cleaned_aggregate
            .aggregate.sum.gross_leasable_area_sqm
        );

        const previewArray = previewTableData.search_preview.map(
          (row: any) => row
        );
        setPreviewData(previewArray);
        const dealsClosedFull =
          dashboardSearchData.ingest_deals_closed_quarterly.map(
            (row: any) => row
          );
        const percentShareFull =
          dashboardSearchData.ingest_industry_percent_share.map(
            (row: any) => row
          );

        setPercentShare(percentShareFull);
        setDealsClosed(dealsClosedFull);
      }
    } catch (err: any) {
      alert(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchType,
    dashboardSearchData,
    devData,
    dashboardData,
    previewTableData,
  ]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${district} Stats from ${moment(fromDate).format(
      "MMM DD, YYYY"
    )} to ${moment(toDate).format("MMM DD, YYYY")}`,
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });
  const ComponentForPrint = (props: any) => {
    return (
      <Grid ref={componentRef} container spacing={2}>
        <Grid item md={6} sm={6} xl={3} xs={12}>
          <TotalLeaseArea totalLeaseValue={totalLeaseArea} />
        </Grid>
        <Grid item md={6} sm={6} xl={3} xs={12}>
          <AverageRentSQM rentValue={avgDataState.current_rent_sqm} />
        </Grid>
        <Grid item md={6} sm={6} xl={3} xs={12}>
          <ModeLeaseTerm leaseTermValue={avgDataState.lease_term} />
        </Grid>
        <Grid item md={6} sm={6} xl={3} xs={12}>
          <ModeFitoutPeriod fitoutValue={avgDataState.fitout_period} />
        </Grid>
        {/* second row */}
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <DealsClosedChart
            dealsClosedObject={dealsClosed}
            searchQuery={autocomplete}
            searchType={searchType}
            districtName={district}
            fromDate={fromDate}
            toDate={toDate}
          />
        </Grid>
        <Grid item lg={4} md={12} sm={12} xs={12} xl={3}>
          <TrafficByDevice
            sx={{ height: "100%" }}
            percentShareObject={percentShare}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1, mb: 3 }}>
            <CardHeader title="Filters" />
            <Divider
              sx={{
                borderColor: "neutral.200",
              }}
            />
            <Grid container spacing={1}>
              <Grid item>
                <Box
                  sx={{
                    ml: 1,
                    mt: 2,
                    mb: 1,
                    mr: 1,
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={searchType}
                      onChange={handleSearchType}
                    >
                      <FormControlLabel
                        value={"district"}
                        control={<Radio />}
                        label="By District"
                      />
                      <FormControlLabel
                        value={"building name"}
                        control={<Radio />}
                        label="By Building Name"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={3} md={6} sm={6} xs={12} xl={3}>
                <SelectFilter
                  selectList={transactionTypeList}
                  handleChange={handleTransactionType}
                  selectedChoice={transactionType}
                  selectLabel="Transaction Type"
                />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12} xl={3}>
                <SelectFilter
                  selectList={brokerList}
                  handleChange={handleBroker}
                  selectedChoice={assignedBroker}
                  selectLabel="Assigned Broker"
                />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12} xl={3}>
                <SelectFilter
                  selectList={districtList}
                  handleChange={handleDistrict}
                  selectedChoice={district}
                  selectLabel="District"
                  disabool={searchType}
                />

                <Box
                  sx={{
                    ml: 1,
                    mt: 2,
                    mb: 2,
                    mr: 1,
                    display: searchType === "building name" ? "flex" : "none",
                  }}
                >
                  <FormControl fullWidth>
                    <Autocomplete
                      freeSolo
                      id="grouped-demo"
                      autoHighlight
                      value={autocomplete}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option: Development) => option.firstLetter}
                      getOptionLabel={(option: string | Development) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // setAutocomplete(option.development_name);
                        return option.development_name;
                      }}
                      onChange={(_, data: any) => {
                        if (data?.development_name) {
                          setAutocomplete(data?.development_name);
                        }
                      }}
                      inputValue={InputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      filterOptions={(options: any, params: any) => {
                        const filtered = filter(
                          options,
                          params
                        ) as Development[];

                        return filtered;
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} label="Building Name" />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12} xl={3}>
                <Box
                  sx={{
                    ml: 1,
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl sx={{ width: "100%" }}>
                      <DesktopDatePicker
                        disableFuture
                        label="From"
                        value={fromDate}
                        onChange={(newValue: any) => {
                          setFromDate(newValue);
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </FormControl>
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12} xl={3}>
                <Box
                  sx={{
                    ml: 1,
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl sx={{ width: "100%" }}>
                      <DesktopDatePicker
                        disableFuture
                        label="To"
                        value={toDate}
                        onChange={(newValue: any) => {
                          setToDate(newValue);
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </FormControl>
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>
            <Divider
              sx={{
                borderColor: "neutral.200",
              }}
            />
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <DisplayTable previewDataObject={previewDataState} />
              <Button onClick={handlePrint}>Print Stats</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <ComponentForPrint />
    </>
  );
};
