import { combineReducers } from "redux";
import { tokenstore } from "./tokenStore";
import { checkedRowStore } from "./checkedRowStore";
const reducers = combineReducers({
  session: tokenstore,
  checked: checkedRowStore,
});

export default reducers;

export type State = ReturnType<typeof reducers>;
