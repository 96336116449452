import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  FormControl,
  Collapse,
  CardHeader,
  InputAdornment,
  Modal,
  Typography,
  Fade,
  Box,
  LinearProgress,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Alert, AlertTitle } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface ISignupUser {
  username: string;
  password: string;
  confirm_password: string;
  given_name: string;
  family_name: string;
  phone: string;
  email: string;
}
const phoneRegExp =
  /\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const userRegex = /^[A-Za-z]{1,2}[0-9]{3}$/;
const specCharRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const emailRegex =
  /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\\.)?[a-zA-Z]+\\.)?(leechiu)\.com$/;

const schema = yup.object().shape({
  username: yup
    .string()
    .required("Username is required")
    .matches(userRegex, "Username must be an LPC Employee ID."),
  password: yup
    .string()
    .required()
    .matches(
      specCharRegex,
      "Need at least eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  email: yup
    .string()
    .email()
    .required("Please type your E-mail!")
    .matches(emailRegex, "Must have Leechiu domain!"),
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  given_name: yup.string(),
  family_name: yup.string(),
});

export const Signup = (props: any) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignupUser>({ resolver: yupResolver(schema) });
  const [errorMsg, handleError] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleErrorAlert = () => {
    setErrorAlert(!errorAlert);
  };

  const onSubmit: SubmitHandler<ISignupUser> = async (
    datum: ISignupUser,
    e: any
  ) => {
    try {
      setLoading(true);
      const { user } = await Auth.signUp({
        username: datum.username,
        password: datum.password,
        attributes: {
          email: datum.email, // optional
          phone_number: `+63${datum.phone}`,
          given_name: datum.given_name,
          family_name: datum.family_name, // optional - E.164 number convention
          // other custom attributes
        },
      });
      setLoading(false);
      handleModal();
    } catch (error: any) {
      setLoading(false);
      handleError(error.message);
      handleErrorAlert();
    }
  };

  return (
    <>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "200ms" : "0ms",
        }}
        unmountOnExit
      >
        <LinearProgress color="primary" />
      </Fade>
      <Collapse in={errorAlert}>
        {!errorMsg ? null : (
          <Alert severity="error" onClose={handleErrorAlert}>
            <AlertTitle>Error</AlertTitle>
            {errorMsg}
          </Alert>
        )}
      </Collapse>

      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={handleModal}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
            }}
          >
            <Card
              sx={{
                border: "none",
              }}
            >
              <CardHeader title="Sign Up Successful" />
              <CardContent>
                <Typography variant="body2">
                  You are now registered. Please check your e-mail to verify
                  your account. You may then log-in the website after the
                  verification.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <Link
                    to="/login"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Back to Homepage
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Fade>
      </Modal>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "90vh",
          width: "calc(100%)",
          mt: 2,
        }}
      >
        <Card
          sx={{
            width: 300,
            mb: 2,
            padding: 2,
          }}
        >
          <CardHeader title="Sign Up for REDMAP" />
          <form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <CardContent>
              <FormControl
                sx={{
                  pb: 2,
                }}
                fullWidth
              >
                <TextField
                  id="standard-name"
                  label="Username"
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  {...register("username")}
                />
              </FormControl>
              <FormControl
                sx={{
                  pb: 2,
                }}
                fullWidth
              >
                <TextField
                  id="password-1"
                  type="password"
                  label="Password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  {...register("password")}
                />
              </FormControl>
              <FormControl
                sx={{
                  pb: 2,
                }}
                fullWidth
              >
                <TextField
                  id="password-2"
                  type="password"
                  label="Confirm Password"
                  error={!!errors.confirm_password}
                  helperText={errors.confirm_password?.message}
                  {...register("confirm_password")}
                />
              </FormControl>
              <FormControl
                sx={{
                  pb: 2,
                }}
                fullWidth
              >
                <TextField
                  id="email"
                  type="email"
                  label="E-mail"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  {...register("email")}
                />
              </FormControl>
              <FormControl
                sx={{
                  pb: 2,
                }}
                fullWidth
              >
                <TextField
                  id="input-with-icon-textfield"
                  label="Phone Number"
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+63</InputAdornment>
                    ),
                  }}
                  {...register("phone")}
                />
              </FormControl>
              <FormControl
                sx={{
                  pb: 2,
                }}
                fullWidth
              >
                <TextField
                  id="first-name"
                  label="First Name"
                  {...register("given_name")}
                />
              </FormControl>
              <FormControl
                sx={{
                  pb: 2,
                }}
                fullWidth
              >
                <TextField
                  id="last-name"
                  label="Family Name"
                  {...register("family_name")}
                />
              </FormControl>
            </CardContent>

            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Sign Up
              </Button>
            </CardActions>
          </form>
        </Card>
        <Button color="primary" fullWidth onClick={() => navigate(-1)}>
          Back
        </Button>
      </Grid>
    </>
  );
};
