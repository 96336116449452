import { Navigate } from "react-router-dom";
import { State } from "../state";
import { useSelector } from "react-redux";
import AuthDrawer from "../components/auth-navbar/auth-drawer";

const PrivateRoute = (props: any) => {
  const { children } = props;
  const state = useSelector((state: State) => state.session);

  if (!state["isAuthenticated"]) {
    return <Navigate to="/login" />;
  }

  return <AuthDrawer titleText="LPC Transactions">{children}</AuthDrawer>;
};

export default PrivateRoute;
