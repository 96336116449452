import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const steps = [
  "Important information",
  "Fill up the necessary dates",
  "Complete the prices",
  "Upload the contracts",
  "Summary",
];

export default function LinearStepper(props: any) {
  const { formStep, setFormStep } = props;
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const handleStep = (step: number) => () => {
    setFormStep(step);
  };

  return (
    <Box sx={{ width: "100%", mt: 1, mb: 3 }}>
      <Stepper activeStep={formStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
