import { useState, createContext, useContext } from "react";
import { Data } from "../utils/types";

type FormContextType = {
  data: Data | undefined;
  contractFile: File | undefined;
  setFormValues: (values: Data) => void;
  setContractFile: (contract: File) => void;
};

export const FormContext = createContext<FormContextType>({
  data: {},
  contractFile: undefined,
  setFormValues: () => undefined,
  setContractFile: () => undefined,
});

export const FormProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [data, setData] = useState<Data>();
  const [contractFile, setFile] = useState<File>();

  const setFormValues = (values: Data) => {
    setData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  const setContractFile = (contract: File) => {
    setFile(contract);
  };

  return (
    <FormContext.Provider
      value={{ data, contractFile, setContractFile, setFormValues }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormData = () => useContext(FormContext);
