import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Login } from "../auth/Login";
import Amplify from "aws-amplify";
import awsconfig from "../aws-exports";
import { Signup } from "../auth/SignUp";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useSelector } from "react-redux";
import { State } from "../state";
import PrivateRoute from "../auth/PrivateRoute";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { ForgotPassword } from "../auth/ForgotPassword";
import { Dashboard } from "./dashboard";
import Contracts from "./contracts";
import { MultiStepForm } from "./multi-step-form";
import { AuditTable } from "../old-pages/AuditTable";

// or 'aws-amplify-react-native';
Amplify.configure(awsconfig);

const httpLink = createHttpLink({
  uri: "https://cl-project-api.hasura.app/v1/graphql",
});

export const App = () => {
  const state = useSelector((state: State) => state.session);
  const token: string = state["token"];
  const wsLink = new WebSocketLink({
    uri: "wss://cl-project-api.hasura.app/v1/graphql",
    options: {
      reconnect: true,
      timeout: 60000,
      connectionParams: () => {
        return { headers: { authorization: token ? `Bearer ${token}` : "" } };
      },
    },
  });
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token !== "" ? `Bearer ${token}` : "",
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/contracts"
            element={
              <PrivateRoute>
                <Contracts />
              </PrivateRoute>
            }
          />
          <Route
            path="/billing"
            element={
              <PrivateRoute>
                <MultiStepForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/logs"
            element={
              <PrivateRoute>
                <AuditTable />
              </PrivateRoute>
            }
          />
          <Route path="/*" element={<Navigate replace to="/login" />} />
        </Routes>
      </Router>
    </ApolloProvider>
  );
};
