import React from "react";
import { Box, Button, ListItem } from "@mui/material";
import { useLocation, Link } from "react-router-dom";

export const NavItem = (props: any) => {
  const { href, icon, title, path, ...others } = props;
  const { pathname } = useLocation();
  const active: Boolean = href ? pathname === href : false;

  return (
    <Link to={href} style={{ color: "inherit", textDecoration: "inherit" }}>
      <ListItem
        disableGutters
        sx={{
          display: "flex",
          mb: 0.5,
          py: 0,
          px: 2,
        }}
        {...others}
      >
        <Button
          component="a"
          startIcon={icon}
          disableGutters
          LinkComponent={Link}
          sx={{
            display: "flex",
            mb: 0.5,
            backgroundColor: active && "rgba(255,255,255, 0.08)",
            borderRadius: 1,
            fontWeight: active && "fontWeightMedium",
            color: active ? "secondary.main" : "neutral.100",
            justifyContent: "flex-start",
            px: 3,
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            "& .MuiButton-startIcon": {
              color: active ? "secondary.main" : "neutral.100",
            },
            "&:hover": {
              backgroundColor: "rgba(255,255,255, 0.08)",
            },
          }}
          {...others}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
        </Button>
      </ListItem>
    </Link>
  );
};
