import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  FormControl,
  Fade,
  Collapse,
  LinearProgress,
  CardHeader,
  Typography,
  Modal,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Alert, AlertTitle } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface IForgotPassword {
  username: string;
  verificationCode: string;
  password: string;
}

const userRegex = /^[A-Za-z]{1,2}[0-9]{3}$/;
const specCharRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const schema = yup.object().shape({
  username: yup
    .string()
    .required("Username is required")
    .matches(userRegex, "Username must be an LPC Employee ID."),
  password: yup
    .string()
    .required()
    .matches(
      specCharRegex,
      "Need at least eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cardRoot: {
      padding: 20,
      width: 300,
      margin: "20px auto",
    },
    margin: {
      paddingBottom: 15,
    },
    gridLogin: {
      width: "calc(100% + 24px)",
      height: "90vh",
    },
  })
);

export const ForgotPassword = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>({
    resolver: yupResolver(schema),
  });
  const [errorMsg, handleError] = useState<string>("");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit: SubmitHandler<IForgotPassword> = async (
    datum: IForgotPassword,
    e: any
  ) => {
    try {
      setLoading(true);
      const user = await Auth.forgotPasswordSubmit(
        datum.username,
        datum.verificationCode,
        datum.password
      );
      setLoading(false);
      setOpen(true);
    } catch (error: any) {
      handleError(error.message);
      setLoading(false);
      setOpenError(true);
    }
  };
  return (
    <>
      {" "}
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "200ms" : "0ms",
        }}
        unmountOnExit
      >
        <LinearProgress color="primary" />
      </Fade>
      <Collapse in={openError}>
        {!errorMsg ? null : (
          <Alert
            severity="error"
            onClose={() => {
              setOpenError(false);
            }}
          >
            <AlertTitle>Error</AlertTitle>
            {errorMsg}
          </Alert>
        )}
      </Collapse>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        className={classes.modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card>
            <CardHeader title="Reset Password Successful" />
            <CardContent>
              <Typography variant="body2">
                Your password is reset. You may now go back to the login page
                and try again.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="contained"
                color="primary"
                fullWidth
              >
                <Link
                  to="/login"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Back to Homepage
                </Link>
              </Button>
            </CardActions>
          </Card>
        </Fade>
      </Modal>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.gridLogin}
      >
        <Card className={classes.cardRoot}>
          <form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <img
              src="https://amplify-final-react-auth-dev-171957-deployment.s3.us-east-2.amazonaws.com/logo-hires-transparent.png"
              alt="Leechiu Property Consultants"
              style={{
                display: "flex",
                margin: "0 auto",
                padding: 15,
                width: "50%",
              }}
            />
            <CardContent>
              <FormControl className={classes.margin} fullWidth>
                <TextField
                  id="standard-name"
                  label="Verification Code"
                  {...register("verificationCode", { required: true })}
                />
              </FormControl>
              <FormControl className={classes.margin} fullWidth>
                <TextField
                  id="standard-name"
                  label="Username"
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  {...register("username")}
                />
              </FormControl>
              <FormControl className={classes.margin} fullWidth>
                <TextField
                  id="standard-uncontrolled"
                  label="New Password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  {...register("password")}
                />
              </FormControl>
            </CardContent>

            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Change Password
              </Button>
            </CardActions>
          </form>
        </Card>
      </Grid>
    </>
  );
};
